import actionTypes from './actionTypes'

export const actionFetchModul = (data) => {
  return {
    type: actionTypes.FETCH_MODUL,
    data
  }
}

export const actionFetchModulStart = () => {
  return {
    type: actionTypes.FETCH_MODUL_START
  }
}

export const actionFetchModulEnd = () => {
  return {
    type: actionTypes.FETCH_MODUL_END
  }
}

export const actionFetchModulSuccess = (data) => {
  return {
    type: actionTypes.FETCH_MODUL_SUCCESS,
    data
  }
}

export const actionCreateModul = (data) => {
  return {
    type: actionTypes.CREATE_MODUL,
    data
  }
}

export const actionCreateModulStart = () => {
  return {
    type: actionTypes.CREATE_MODUL_START
  }
}

export const actionCreateModulEnd = () => {
  return {
    type: actionTypes.CREATE_MODUL_END
  }
}

export const actionCreateModulSuccess = (data) => {
  return {
    type: actionTypes.CREATE_MODUL_SUCCESS,
    data
  }
}

export const actionCreateModulFailed = (data) => {
  return {
    type: actionTypes.CREATE_MODUL_FAILED,
    data
  }
}

export const actionUpdateModul = (data) => {
  return {
    type: actionTypes.UPDATE_MODUL,
    data
  }
}

export const actionUpdateModulStart = () => {
  return {
    type: actionTypes.UPDATE_MODUL_START
  }
}

export const actionUpdateModulEnd = () => {
  return {
    type: actionTypes.UPDATE_MODUL_END
  }
}

export const actionUpdateModulSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_MODUL_SUCCESS,
    data
  }
}

export const actionUpdateModulFailed = (data) => {
  return {
    type: actionTypes.UPDATE_MODUL_FAILED,
    data
  }
}

export const actionResetFormModul = () => {
  return {
    type: actionTypes.RESET_FORM_MODUL
  }
}

export const actionDeleteModul = (data) => {
  return {
    type: actionTypes.DELETE_MODUL,
    data
  }
}

export const actionDeleteModulStart = () => {
  return {
    type: actionTypes.DELETE_MODUL_START
  }
}

export const actionDeleteModulEnd = () => {
  return {
    type: actionTypes.DELETE_MODUL_END
  }
}

export const actionDeleteModulSuccess = () => {
  return {
    type: actionTypes.DELETE_MODUL_SUCCESS
  }
}

export const actionDeleteModulFailed = () => {
  return {
    type: actionTypes.DELETE_MODUL_FAILED
  }
}

export const actionResetDeleteModul = () => {
  return {
    type: actionTypes.RESET_DELETE_MODUL
  }
}