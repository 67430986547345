import { useEffect } from 'react'

const UseScript = ({ src }) => {
    useEffect(() => {
        const script = document.createElement('script')

        script.src = src
        script.async = false

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [src])

    return (null)
}

export default UseScript