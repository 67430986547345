import actionTypes from './actionTypes'

export const actionFetchPengajar = (data) => {
  return {
    type: actionTypes.FETCH_PENGAJAR,
    data
  }
}

export const actionFetchPengajarStart = () => {
  return {
    type: actionTypes.FETCH_PENGAJAR_START
  }
}

export const actionFetchPengajarEnd = () => {
  return {
    type: actionTypes.FETCH_PENGAJAR_END
  }
}

export const actionFetchPengajarSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PENGAJAR_SUCCESS,
    data
  }
}

export const actionCreatePengajar = (data) => {
  return {
    type: actionTypes.CREATE_PENGAJAR,
    data
  }
}

export const actionCreatePengajarStart = () => {
  return {
    type: actionTypes.CREATE_PENGAJAR_START
  }
}

export const actionCreatePengajarEnd = () => {
  return {
    type: actionTypes.CREATE_PENGAJAR_END
  }
}

export const actionCreatePengajarSuccess = (data) => {
  return {
    type: actionTypes.CREATE_PENGAJAR_SUCCESS,
    data
  }
}

export const actionCreatePengajarFailed = (data) => {
  return {
    type: actionTypes.CREATE_PENGAJAR_FAILED,
    data
  }
}

export const actionUpdatePengajar = (data) => {
  return {
    type: actionTypes.UPDATE_PENGAJAR,
    data
  }
}

export const actionUpdatePengajarStart = () => {
  return {
    type: actionTypes.UPDATE_PENGAJAR_START
  }
}

export const actionUpdatePengajarEnd = () => {
  return {
    type: actionTypes.UPDATE_PENGAJAR_END
  }
}

export const actionUpdatePengajarSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_PENGAJAR_SUCCESS,
    data
  }
}

export const actionUpdatePengajarFailed = (data) => {
  return {
    type: actionTypes.UPDATE_PENGAJAR_FAILED,
    data
  }
}

export const actionResetFormPengajar = () => {
  return {
    type: actionTypes.RESET_FORM_PENGAJAR
  }
}

export const actionDeletePengajar = (data) => {
  return {
    type: actionTypes.DELETE_PENGAJAR,
    data
  }
}

export const actionDeletePengajarStart = () => {
  return {
    type: actionTypes.DELETE_PENGAJAR_START
  }
}

export const actionDeletePengajarEnd = () => {
  return {
    type: actionTypes.DELETE_PENGAJAR_END
  }
}

export const actionDeletePengajarSuccess = () => {
  return {
    type: actionTypes.DELETE_PENGAJAR_SUCCESS
  }
}

export const actionDeletePengajarFailed = () => {
  return {
    type: actionTypes.DELETE_PENGAJAR_FAILED
  }
}

export const actionResetDeletePengajar = () => {
  return {
    type: actionTypes.RESET_DELETE_PENGAJAR
  }
}