import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionCreateTes, actionResetFormTes } from "../../store/tes/actions"
import { alert } from "../../utils/alert"
import { pushUrl } from "../../utils/url"
import Dropdown from "../../components/Form/Dropdown"
import TextEditor from "../../components/Form/TextEditor"
import { request } from "../../utils/request"
import SelectSearch from "../../components/Form/SelectSearch"

const TesCreate = (props) => {
    const { actionCreateTes, actionResetFormTes, accessToken, form } = props

    const [payload, setPayload] = useState({
        pertanyaan: "",
        kelompok_id: "",
        status: ""
    })
    const [errors, setErrors] = useState({})
    const [submit, setSubmit] = useState(false)
    const [optKelompok, setOptKelompok] = useState([])

    useEffect(() => {
        const titlePage = "Tambah Tes"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        actionResetFormTes()
        getKelompok()

        return () => actionResetFormTes()
    }, [])

    const getKelompok = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_KELOMPOK + '/all', {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setOptKelompok(res?.result)
            }
        } catch (error) {
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    useEffect(async () => {
        if (submit === true) {
            if (form?.success === true) {
                await alert({ title: "Berhasil", html: form?.message, icon: "success" })
                pushUrl("/tes")
            } else if (form?.success === false) {
                await alert({ title: "Gagal", html: form?.message, icon: "error" })
            }
        }
    }, [form?.success, submit])

    const onChange = ((field, value) => {
        payload[field] = value

        setPayload({
            ...payload,
        })
    })

    const onSubmit = (e) => {
        if (validate() === true) {
            setSubmit(true)

            actionCreateTes({
                payload,
                accessToken
            })
        }

        e.preventDefault()
    }

    const validate = () => {
        let jsonErrors = {}
        let hasError = false

        if (!payload?.pertanyaan || payload?.pertanyaan === "") {
            hasError = true
            jsonErrors["pertanyaan"] = "Pertanyaan harus diisi"
        }
        if (!payload?.kelompok_id || payload?.kelompok_id?.length < 1) {
            hasError = true
            jsonErrors["kelompok_id"] = "Kelompok harus diisi"
        }
        if (!payload?.status || payload?.status === "") {
            hasError = true
            jsonErrors["status"] = "Status harus diisi"
        }

        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <Link to={process.env.REACT_APP_SUBDIR + "/tes"} className="btn btn-white mb-2 btn-md-block">Kembali</Link>

            <div className="card mt-3">
                <div className="card-body">
                    <form onSubmit={onSubmit} >
                        <div className="row">
                            <div className="col-lg-12 col-xl-10 col-xxl-8">
                                <TextEditor
                                    id={"pertanyaan"}
                                    name={"pertanyaan"}
                                    placeholder={"Masukkan pertanyaan"}
                                    label={"Pertanyaan"}
                                    onChange={(value) => onChange('pertanyaan', value)}
                                    required={true}
                                    value={payload?.pertanyaan}
                                    error={errors?.pertanyaan}
                                    errors={form?.errors?.pertanyaan}
                                    tabIndex={1} />

                                <SelectSearch
                                    id={"kelompok_id"}
                                    name={"kelompok_id"}
                                    placeholder={"Pilih kelompok"}
                                    label={"Kelompok"}
                                    onChange={(e) => onChange('kelompok_id', e?.value)}
                                    required={true}
                                    value={payload?.kelompok_id}
                                    error={errors?.kelompok_id}
                                    errors={form?.errors?.kelompok_id}
                                    tabIndex={2}
                                    options={optKelompok}
                                />

                                <Dropdown
                                    id={"status"}
                                    name={"status"}
                                    placeholder={"Pilih status"}
                                    label={"Status"}
                                    onChange={(e) => onChange('status', e?.target?.value)}
                                    required={true}
                                    value={payload?.status}
                                    error={errors?.status}
                                    errors={form?.errors?.status}
                                    tabIndex={3}
                                    options={[
                                        { label: "Aktif", value: "Aktif" },
                                        { label: "Tidak Aktif", value: "Tidak Aktif" }
                                    ]} />

                                <button
                                    tabIndex={4}
                                    type="submit" className="btn btn-primary btn-md-block" disabled={form?.loading === true}>
                                    Kirim
                                    {form?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken }, tes: { form } } = state

    return { accessToken, form }
}

export default withRouter(connect(mapStateToProps, { actionCreateTes, actionResetFormTes })(TesCreate))