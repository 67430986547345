import React from "react"

const Pagination = ({ page, last_page, pageUrl, onChange }) => {
    return <React.Fragment>
        <nav aria-label="Page navigation">
            <ul className="pagination pagination-square pagination-primary">
                <li className="page-item first">
                    <a href="/#" onClick={(e) => onChange(1, e)} className={"page-link " + (page > 2 ? "" : "disabled")} ><i className="tf-icon bx bx-chevrons-left"></i></a>
                </li>
                <li className="page-item prev">
                    <a href="/#" onClick={(e) => onChange(page - 1, e)} className={"page-link " + (page > 1 ? "" : "disabled")}><i className="tf-icon bx bx-chevron-left"></i></a>
                </li>
                {
                    pageUrl?.links?.map((val, key) => {
                        if (!['pagination.previous', 'pagination.next'].includes(val?.label)) {
                            return <li key={key} className={"page-item " + (page === parseInt(val?.label) ? "active" : "")}>
                                {
                                    val?.label === "..." ?
                                        <a href="/#" onClick={(e) => e.preventDefault()} className="page-link">{val?.label}</a>
                                        :
                                        <a href="/#" onClick={(e) => onChange(parseInt(val?.label), e)} className="page-link">{val?.label}</a>
                                }
                            </li>
                        }

                        return null
                    })
                }
                <li className="page-item next">
                    <a href="/#" onClick={(e) => onChange(page + 1, e)} className={"page-link " + (page >= last_page ? "disabled" : "")}><i className="tf-icon bx bx-chevron-right"></i></a>
                </li>
                <li className="page-item last">
                    <a href="/#" onClick={(e) => onChange(last_page, e)} className={"page-link " + (page + 1 >= last_page ? "disabled" : "")}><i className="tf-icon bx bx-chevrons-right"></i></a>
                </li>
            </ul>
        </nav>
    </React.Fragment>
}

export default Pagination