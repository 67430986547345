import Immutable from 'seamless-immutable'

const FETCH_KURSUS = 'FETCH_KURSUS'
const FETCH_KURSUS_START = 'FETCH_KURSUS_START'
const FETCH_KURSUS_END = 'FETCH_KURSUS_END'
const FETCH_KURSUS_SUCCESS = 'FETCH_KURSUS_SUCCESS'
const FETCH_KURSUS_FAILED = 'FETCH_KURSUS_FAILED'

const CREATE_KURSUS = 'CREATE_KURSUS'
const CREATE_KURSUS_START = 'CREATE_KURSUS_START'
const CREATE_KURSUS_END = 'CREATE_KURSUS_END'
const CREATE_KURSUS_SUCCESS = 'CREATE_KURSUS_SUCCESS'
const CREATE_KURSUS_FAILED = 'CREATE_KURSUS_FAILED'

const UPDATE_KURSUS = 'UPDATE_KURSUS'
const UPDATE_KURSUS_START = 'UPDATE_KURSUS_START'
const UPDATE_KURSUS_END = 'UPDATE_KURSUS_END'
const UPDATE_KURSUS_SUCCESS = 'UPDATE_KURSUS_SUCCESS'
const UPDATE_KURSUS_FAILED = 'UPDATE_KURSUS_FAILED'

const DELETE_KURSUS = 'DELETE_KURSUS'
const DELETE_KURSUS_START = 'DELETE_KURSUS_START'
const DELETE_KURSUS_END = 'DELETE_KURSUS_END'
const DELETE_KURSUS_SUCCESS = 'DELETE_KURSUS_SUCCESS'
const DELETE_KURSUS_FAILED = 'DELETE_KURSUS_FAILED'

const RESET_FORM_KURSUS = 'RESET_FORM_KURSUS'
const RESET_DELETE_KURSUS = 'RESET_DELETE_KURSUS'

const actionTypes = Immutable({
  FETCH_KURSUS,
  FETCH_KURSUS_START,
  FETCH_KURSUS_END,
  FETCH_KURSUS_SUCCESS,
  FETCH_KURSUS_FAILED,

  CREATE_KURSUS,
  CREATE_KURSUS_START,
  CREATE_KURSUS_END,
  CREATE_KURSUS_SUCCESS,
  CREATE_KURSUS_FAILED,

  UPDATE_KURSUS,
  UPDATE_KURSUS_START,
  UPDATE_KURSUS_END,
  UPDATE_KURSUS_SUCCESS,
  UPDATE_KURSUS_FAILED,

  DELETE_KURSUS,
  DELETE_KURSUS_START,
  DELETE_KURSUS_END,
  DELETE_KURSUS_SUCCESS,
  DELETE_KURSUS_FAILED,

  RESET_FORM_KURSUS,
  RESET_DELETE_KURSUS
})

export default actionTypes
