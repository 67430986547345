import Immutable from 'seamless-immutable'
import actionTypes from './actionTypes'

let model = {
  user: null,
  accessToken: null,
  expiredAt: null,
  loading: false,
  success: null
}
export const INITIAL_STATE = Immutable(model)

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.SIGN_IN_START:
      return {
        ...state, 
        loading: true,
        success: null
      }
    case actionTypes.SIGN_IN_END:
      return { ...state, loading: false }
    case actionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        success: true,
        user: action.data?.user,
        accessToken: action.data?.accessToken,
        expiredAt: action.data?.expiredAt
      }
    case actionTypes.SET_EXPIRED:
      return { ...state, expiredAt: action.data }
    case actionTypes.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        accessToken: action.data?.accessToken,
        expiredAt: action.data?.expiredAt
      }
    case actionTypes.LOGOUT:
      return { ...model }
    default:
      return state
  }
}

export default reducer
