import actionTypes from './actionTypes'

export const actionFetchLaporanTugasPelajar = (data) => {
  return {
    type: actionTypes.FETCH_LAPORAN_TUGAS_PELAJAR,
    data
  }
}

export const actionFetchLaporanTugasPelajarStart = () => {
  return {
    type: actionTypes.FETCH_LAPORAN_TUGAS_PELAJAR_START
  }
}

export const actionFetchLaporanTugasPelajarEnd = () => {
  return {
    type: actionTypes.FETCH_LAPORAN_TUGAS_PELAJAR_END
  }
}

export const actionFetchLaporanTugasPelajarSuccess = (data) => {
  return {
    type: actionTypes.FETCH_LAPORAN_TUGAS_PELAJAR_SUCCESS,
    data
  }
}