import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionCreateRangeNilai, actionResetFormRangeNilai } from "../../store/range-nilai/actions"
import { alert } from "../../utils/alert"
import { pushUrl } from "../../utils/url"
import Dropdown from "../../components/Form/Dropdown"
import { request } from "../../utils/request"
import NumberInput from "../../components/Form/NumberInput"
import TextInput from "../../components/Form/TextInput"

const RangeNilaiCreate = (props) => {
    const { actionCreateRangeNilai, actionResetFormRangeNilai, accessToken, form } = props

    const [payload, setPayload] = useState({
        hasil: "",
        nilai_awal: "",
        nilai_akhir: "",
        status: ""
    })
    const [errors, setErrors] = useState({})
    const [submit, setSubmit] = useState(false)

    useEffect(() => {
        const titlePage = "Tambah Range Nilai"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        actionResetFormRangeNilai()

        return () => actionResetFormRangeNilai()
    }, [])

    useEffect(async () => {
        if (submit === true) {
            if (form?.success === true) {
                await alert({ title: "Berhasil", html: form?.message, icon: "success" })
                pushUrl("/range-nilai")
            } else if (form?.success === false) {
                await alert({ title: "Gagal", html: form?.message, icon: "error" })
            }
        }
    }, [form?.success, submit])

    const onChange = ((field, value) => {
        let pass = true
        if (field === "nilai_awal" || field === "nilai_akhir") {
            if (value > 100) {
                pass = false
            } else if (value < 0) {
                pass = false
            }
        }

        if (pass === true) {
            payload[field] = value

            setPayload({
                ...payload,
            })
        }
    })

    const onSubmit = (e) => {
        if (validate() === true) {
            setSubmit(true)

            actionCreateRangeNilai({
                payload,
                accessToken
            })
        }

        e.preventDefault()
    }

    const validate = () => {
        let jsonErrors = {}
        let hasError = false

        if (!payload?.hasil || payload?.hasil === "") {
            hasError = true
            jsonErrors["hasil"] = "Hasil harus diisi"
        }
        if (!payload?.nilai_awal || payload?.nilai_awal === "") {
            hasError = true
            jsonErrors["nilai_awal"] = "Nilai awal harus diisi"
        }
        if (!payload?.nilai_akhir || payload?.nilai_akhir === "") {
            hasError = true
            jsonErrors["nilai_akhir"] = "Nilai akhir harus diisi"
        }
        if (!payload?.status || payload?.status === "") {
            hasError = true
            jsonErrors["status"] = "Status harus diisi"
        }

        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <Link to={process.env.REACT_APP_SUBDIR + "/range-nilai"} className="btn btn-white mb-2 btn-md-block">Kembali</Link>

            <div className="card mt-3">
                <div className="card-body">
                    <form onSubmit={onSubmit} >
                        <div className="row">
                            <div className="col-lg-12 col-xl-10 col-xxl-8">
                                <TextInput
                                    id={"hasil"}
                                    name={"hasil"}
                                    placeholder={"Masukkan hasil"}
                                    label={"Hasil"}
                                    onChange={(e) => onChange('hasil', e?.target?.value)}
                                    required={true}
                                    value={payload?.hasil}
                                    error={errors?.hasil}
                                    errors={form?.errors?.hasil}
                                    tabIndex={1} />

                                <NumberInput
                                    id={"nilai_awal"}
                                    name={"nilai_awal"}
                                    placeholder={"Masukkan nilai awal"}
                                    label={"Nilai Awal"}
                                    onChange={(e) => onChange('nilai_awal', e?.target?.value)}
                                    required={true}
                                    value={payload?.nilai_awal}
                                    error={errors?.nilai_awal}
                                    errors={form?.errors?.nilai_awal}
                                    tabIndex={2} />

                                <NumberInput
                                    id={"nilai_akhir"}
                                    name={"nilai_akhir"}
                                    placeholder={"Masukkan nilai akhir"}
                                    label={"Nilai Akhir"}
                                    onChange={(e) => onChange('nilai_akhir', e?.target?.value)}
                                    required={true}
                                    value={payload?.nilai_akhir}
                                    error={errors?.nilai_akhir}
                                    errors={form?.errors?.nilai_akhir}
                                    tabIndex={3} />

                                <Dropdown
                                    id={"status"}
                                    name={"status"}
                                    placeholder={"Pilih status"}
                                    label={"Status"}
                                    onChange={(e) => onChange('status', e?.target?.value)}
                                    required={true}
                                    value={payload?.status}
                                    error={errors?.status}
                                    errors={form?.errors?.status}
                                    tabIndex={4}
                                    options={[
                                        { label: "Aktif", value: "Aktif" },
                                        { label: "Tidak Aktif", value: "Tidak Aktif" }
                                    ]} />

                                <button
                                    tabIndex={5}
                                    type="submit" className="btn btn-primary btn-md-block" disabled={form?.loading === true}>
                                    Kirim
                                    {form?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken }, rangeNilai: { form } } = state

    return { accessToken, form }
}

export default withRouter(connect(mapStateToProps, { actionCreateRangeNilai, actionResetFormRangeNilai })(RangeNilaiCreate))