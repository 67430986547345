import Immutable from 'seamless-immutable'

const SIGN_IN = 'SIGN_IN'
const SIGN_IN_START = 'SIGN_IN_START'
const SIGN_IN_END = 'SIGN_IN_END'
const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
const SIGN_IN_FAILED = 'SIGN_IN_FAILED'
const SET_EXPIRED = 'SET_EXPIRED'
const REFRESH_TOKEN = 'REFRESH_TOKEN'
const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS'
const LOGOUT = 'LOGOUT'

const actionTypes = Immutable({
  SIGN_IN,
  SIGN_IN_START,
  SIGN_IN_END,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILED,
  SET_EXPIRED,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  LOGOUT
})

export default actionTypes
