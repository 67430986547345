import { takeEvery, put, all, call, select } from 'redux-saga/effects'
import actionTypes from './actionTypes'
import { request } from '../../utils/request'
import { alert } from '../../utils/alert'
import { actionUpdatePertemuanEnd, actionUpdatePertemuanFailed, actionUpdatePertemuanStart, actionUpdatePertemuanSuccess, actionCreatePertemuanEnd, actionCreatePertemuanFailed, actionCreatePertemuanStart, actionCreatePertemuanSuccess, actionFetchPertemuanEnd, actionFetchPertemuanStart, actionFetchPertemuanSuccess, actionDeletePertemuanSuccess, actionDeletePertemuanFailed, actionDeletePertemuanStart, actionDeletePertemuanEnd } from './actions'
import { pushUrl } from '../../utils/url'

function* sagaFetchPertemuan(params) {
  yield put(actionFetchPertemuanStart())

  const { data: { accessToken, search, page, sort, perPage } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let sortParam = ""
    if (sort?.by) {
      sortParam += "&sortBy=" + sort?.by
    }
    if (sort?.type) {
      sortParam += "&sortType=" + sort?.type
    }

    let perPageParam = perPage ? "&perPage=" + perPage : ""

    let pageParam = page ? "?page=" + page : "?page=1"

    let searchParam = search ? "&search=" + search : ""

    let url = `${process.env.REACT_APP_SERVICE_PERTEMUAN}${pageParam}${searchParam}${sortParam}${perPageParam}`
    const res = yield call(request, url, {
      method: 'GET',
      headers: header
    })

    if (res?.success === true) {
      let data = {
        list: res?.result?.data,
        page: res?.result?.current_page,
        pageUrl: {
          first_page_url: res?.result?.first_page_url,
          links: res?.result?.links,
          last_page_url: res?.result?.last_page_url
        },
        last_page: res?.result?.last_page,
        from: res?.result?.from,
        to: res?.result?.to,
        total: res?.result?.total
      }
      yield put(actionFetchPertemuanSuccess(data))
    } else {
      alert({ title: "Gagal", html: res?.message })
    }
  } catch (err) {
    alert({ title: "Gagal" })
    // yield put(actionSignInFailed())
  }

  yield put(actionFetchPertemuanEnd())
}

function* sagaCreatePertemuan(params) {
  yield put(actionCreatePertemuanStart())

  const { data: { accessToken, payload } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_PERTEMUAN}`
    const res = yield call(request, url, {
      method: 'POST',
      headers: headerPost,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionCreatePertemuanSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionCreatePertemuanFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal menambah data",
      errors: null
    }

    yield put(actionCreatePertemuanFailed(data))
  }

  yield put(actionCreatePertemuanEnd())
}

function* sagaUpdatePertemuan(params) {
  yield put(actionUpdatePertemuanStart())

  const { data: { accessToken, payload, id } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_PERTEMUAN}/${id}`
    const res = yield call(request, url, {
      method: 'PUT',
      headers: headerPost,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionUpdatePertemuanSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionUpdatePertemuanFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal merubah data",
      errors: null
    }

    yield put(actionUpdatePertemuanFailed(data))
  }

  yield put(actionUpdatePertemuanEnd())
}

function* sagaDeletePertemuan(params) {
  yield put(actionDeletePertemuanStart())

  const { data: { accessToken, id } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_PERTEMUAN}/${id}`
    const res = yield call(request, url, {
      method: 'DELETE',
      headers: header
    })

    if (res?.success === true) {
      yield put(actionDeletePertemuanSuccess())
    } else {
      yield put(actionDeletePertemuanFailed())
    }
  } catch (err) {
    yield put(actionDeletePertemuanFailed())
  }

  yield put(actionDeletePertemuanEnd())
}

function* pertemuanSaga() {
  yield all([
    takeEvery(actionTypes.FETCH_PERTEMUAN, sagaFetchPertemuan),
    takeEvery(actionTypes.CREATE_PERTEMUAN, sagaCreatePertemuan),
    takeEvery(actionTypes.UPDATE_PERTEMUAN, sagaUpdatePertemuan),
    takeEvery(actionTypes.DELETE_PERTEMUAN, sagaDeletePertemuan),
  ])
}

export default pertemuanSaga