import actionTypes from './actionTypes'

export const actionFetchLaporanCapaianPelajar = (data) => {
  return {
    type: actionTypes.FETCH_LAPORAN_CAPAIAN_PELAJAR,
    data
  }
}

export const actionFetchLaporanCapaianPelajarStart = () => {
  return {
    type: actionTypes.FETCH_LAPORAN_CAPAIAN_PELAJAR_START
  }
}

export const actionFetchLaporanCapaianPelajarEnd = () => {
  return {
    type: actionTypes.FETCH_LAPORAN_CAPAIAN_PELAJAR_END
  }
}

export const actionFetchLaporanCapaianPelajarSuccess = (data) => {
  return {
    type: actionTypes.FETCH_LAPORAN_CAPAIAN_PELAJAR_SUCCESS,
    data
  }
}