import Immutable from 'seamless-immutable'

const FETCH_TES = 'FETCH_TES'
const FETCH_TES_START = 'FETCH_TES_START'
const FETCH_TES_END = 'FETCH_TES_END'
const FETCH_TES_SUCCESS = 'FETCH_TES_SUCCESS'
const FETCH_TES_FAILED = 'FETCH_TES_FAILED'

const IMPORT_TES = 'IMPORT_TES'
const IMPORT_TES_START = 'IMPORT_TES_START'
const IMPORT_TES_END = 'IMPORT_TES_END'
const IMPORT_TES_SUCCESS = 'IMPORT_TES_SUCCESS'
const IMPORT_TES_FAILED = 'IMPORT_TES_FAILED'

const CREATE_TES = 'CREATE_TES'
const CREATE_TES_START = 'CREATE_TES_START'
const CREATE_TES_END = 'CREATE_TES_END'
const CREATE_TES_SUCCESS = 'CREATE_TES_SUCCESS'
const CREATE_TES_FAILED = 'CREATE_TES_FAILED'

const UPDATE_TES = 'UPDATE_TES'
const UPDATE_TES_START = 'UPDATE_TES_START'
const UPDATE_TES_END = 'UPDATE_TES_END'
const UPDATE_TES_SUCCESS = 'UPDATE_TES_SUCCESS'
const UPDATE_TES_FAILED = 'UPDATE_TES_FAILED'

const DELETE_TES = 'DELETE_TES'
const DELETE_TES_START = 'DELETE_TES_START'
const DELETE_TES_END = 'DELETE_TES_END'
const DELETE_TES_SUCCESS = 'DELETE_TES_SUCCESS'
const DELETE_TES_FAILED = 'DELETE_TES_FAILED'

const RESET_FORM_TES = 'RESET_FORM_TES'
const RESET_DELETE_TES = 'RESET_DELETE_TES'

const actionTypes = Immutable({
  FETCH_TES,
  FETCH_TES_START,
  FETCH_TES_END,
  FETCH_TES_SUCCESS,
  FETCH_TES_FAILED,

  IMPORT_TES,
  IMPORT_TES_START,
  IMPORT_TES_END,
  IMPORT_TES_SUCCESS,
  IMPORT_TES_FAILED,

  CREATE_TES,
  CREATE_TES_START,
  CREATE_TES_END,
  CREATE_TES_SUCCESS,
  CREATE_TES_FAILED,

  UPDATE_TES,
  UPDATE_TES_START,
  UPDATE_TES_END,
  UPDATE_TES_SUCCESS,
  UPDATE_TES_FAILED,

  DELETE_TES,
  DELETE_TES_START,
  DELETE_TES_END,
  DELETE_TES_SUCCESS,
  DELETE_TES_FAILED,

  RESET_FORM_TES,
  RESET_DELETE_TES
})

export default actionTypes
