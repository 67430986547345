import moment from "moment/moment"

const monthName = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember"
]

/** From: 20230204 yyyymmdd */
/** To: 2 Februari 2023 */
export const asDate = date => {
    if (!date) return
    let year = parseInt(date?.substring(0, 4))
    let month = parseInt(date?.substring(4, 6))
    let day = parseInt(date?.substring(6, 8))

    let monthNm = month ? monthName[month - 1] : ""

    let res = `${!isNaN(day) ? day : ""} ${monthNm} ${!isNaN(year) ? year : ""}`.toString()

    if (res?.toLowerCase().includes("undefined") === true) {
        return "Tanggal Tidak Valid"
    }

    return res
}

/** From: 02/04/2023 mmddyyyy */
/** To: 2 Februari 2023 */
export const asDateV2 = date => {
    if (!date || date === "//") return
    let year = parseInt(date?.substring(6, 10))
    let day = parseInt(date?.substring(3, 5))
    let month = parseInt(date?.substring(0, 2))

    let monthNm = month ? monthName[month - 1] : ""

    let res = `${!isNaN(day) ? day : ""} ${monthNm} ${!isNaN(year) ? year : ""}`.toString()

    if (res?.toLowerCase().includes("undefined") === true) {
        return "Tanggal Tidak Valid"
    }

    return res
}

/** From: 02/04/2023 mmddyyyy */
/** To: 2-Feb-2023 */
export const asDateV3 = date => {
    if (!date || date === "//") return
    let year = parseInt(date?.substring(6, 10))
    let day = parseInt(date?.substring(3, 5))
    let month = parseInt(date?.substring(0, 2))

    let monthNm = month ? monthName[month - 1] : ""
    let montChar = monthNm?.substring(0, 3)

    let res = `${!isNaN(day) ? day : ""}-${montChar}-${!isNaN(year) ? year : ""}`.toString()

    if (res?.toLowerCase().includes("undefined") === true) {
        return "Tanggal Tidak Valid"
    }

    return res
}

export const maskString = (val, from = -4) => {
    if (!val) return
    let valString = val.toString()
    let lastChar_ = lastChar(valString, from)
    let valWoLastChar = valString.replace(lastChar_, "")
    let mask = ""

    for (let index = 0; index < valWoLastChar.length; index++) {
        mask += "x"
    }

    return mask + lastChar_
}

export const leadingZero = (val, size) => {
    if (val === null) val = 0
    val = val?.toString()
    while (val?.length < size) val = "0" + val
    return val
}

export const asCurrency = (val) => {
    if (!val) return 0
    val = val?.toString()?.replace(".", ",")
    return val?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

export const asNumber = (val) => {
    if (!val) return 0
    return parseInt(val?.replace(/\./g, ""))
}

export const asTime = (duration, min = false, showDays = false) => {
    if (!duration) duration = 0

    let seconds = Math.floor(duration % 60),
        minutes = Math.floor((duration / 60) % 60),
        hours = Math.floor((duration / (60 * 60)) % 24),
        days = Math.floor(duration / (60 * 60 * 24))

    hours = (hours < 10) ? "0" + hours : hours
    minutes = (minutes < 10) ? "0" + minutes : minutes
    seconds = (seconds < 10) ? "0" + seconds : seconds
    days = (days < 10) ? "0" + days : days

    if (min === true) {
        return minutes + ":" + seconds
    } else if (showDays === true) {
        return days + ":" + hours + ":" + minutes + ":" + seconds
    }
    return hours + ":" + minutes + ":" + seconds
}

export const lastChar = (val, from = -4) => {
    return val ? val.slice(from) : null
}

export const separatorEach4Char = (val, each = 4) => {
    if (!val) return ""
    let valString = val.toString()
    const newVal = valString.replace(/\s/g, '').match(/.{1,4}/g)

    return newVal.join(' ')
}

export const generateRandomChar = (limit = 6, numberOnly = false) => {
    if (numberOnly === true) {
        return Math.floor(100000 + Math.random() * 900000)
    }
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < limit; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

export const maskPhoneNumber = (val) => {
    if (!val) return
    if (val?.toString() === "-" || val?.length < 3) return val

    val = val?.replace(/\s/g, '')

    let length = val.length
    let result = ""

    for (let i = 0; i < length; i++) {
        if (i < parseInt(length / 3) || i > (length - (parseInt(length / 3) + 1))) {
            result += val[i]
        } else {
            result += "*"
        }
    }

    return result
}

export const formatDate = (x) => {
    let monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    let month = x.slice(4, 6);
    let dateNew =
        x.slice(6, 8) + "-" + monthNames[month - 1] + "-" + x.slice(0, 4); // format dd-mmm-yyyy

    return dateNew;
}

export const timerCountdown = (val) => {
    let disp = '00:00'
    if (!val) return disp

    return disp
}

export const initialName = (name) => {
    if (!name) return ""

    let initial = ""
    let arrName = name?.split(" ")

    if (arrName?.length >= 1) {
        initial += arrName[0][0]

        if (arrName?.length > 1) {
            initial += arrName[arrName?.length - 1][0]
        }
    }

    return initial
}

export const strtotime = (date, format = 'MM-DD-YYYY') => {
    if (!date) return null

    let dateObj = new Date(date)
    var momentObj = moment(dateObj, format)
    return momentObj.valueOf()
}

export const dateTime = (time, format = 'DD-MM-YYYY') => {
    let dateObj = moment(time);
    return dateObj.format(format);
}

export const implode = (arr, separator) => {
    if (!arr) return null

    return arr.join(separator)
}

export const asTimeWithLabel = (duration) => {
    if (!duration) duration = 0

    let seconds = Math.floor(duration % 60),
        minutes = Math.floor((duration / 60) % 60),
        hours = Math.floor((duration / (60 * 60)) % 24),
        days = Math.floor(duration / (60 * 60 * 24))

    if (days > 0) {
        return days + " hari " + hours + " jam " + minutes + " menit " + seconds + " detik"
    } else if (hours > 0) {
        return hours + " jam " + minutes + " menit " + seconds + " detik"
    } else if (minutes > 0) {
        return minutes + " menit " + seconds + " detik"
    } else if (seconds > 0) {
        return seconds + " detik"
    }
}