import Immutable from 'seamless-immutable'

const FETCH_PENGAJAR = 'FETCH_PENGAJAR'
const FETCH_PENGAJAR_START = 'FETCH_PENGAJAR_START'
const FETCH_PENGAJAR_END = 'FETCH_PENGAJAR_END'
const FETCH_PENGAJAR_SUCCESS = 'FETCH_PENGAJAR_SUCCESS'
const FETCH_PENGAJAR_FAILED = 'FETCH_PENGAJAR_FAILED'

const CREATE_PENGAJAR = 'CREATE_PENGAJAR'
const CREATE_PENGAJAR_START = 'CREATE_PENGAJAR_START'
const CREATE_PENGAJAR_END = 'CREATE_PENGAJAR_END'
const CREATE_PENGAJAR_SUCCESS = 'CREATE_PENGAJAR_SUCCESS'
const CREATE_PENGAJAR_FAILED = 'CREATE_PENGAJAR_FAILED'

const UPDATE_PENGAJAR = 'UPDATE_PENGAJAR'
const UPDATE_PENGAJAR_START = 'UPDATE_PENGAJAR_START'
const UPDATE_PENGAJAR_END = 'UPDATE_PENGAJAR_END'
const UPDATE_PENGAJAR_SUCCESS = 'UPDATE_PENGAJAR_SUCCESS'
const UPDATE_PENGAJAR_FAILED = 'UPDATE_PENGAJAR_FAILED'

const DELETE_PENGAJAR = 'DELETE_PENGAJAR'
const DELETE_PENGAJAR_START = 'DELETE_PENGAJAR_START'
const DELETE_PENGAJAR_END = 'DELETE_PENGAJAR_END'
const DELETE_PENGAJAR_SUCCESS = 'DELETE_PENGAJAR_SUCCESS'
const DELETE_PENGAJAR_FAILED = 'DELETE_PENGAJAR_FAILED'

const RESET_FORM_PENGAJAR = 'RESET_FORM_PENGAJAR'
const RESET_DELETE_PENGAJAR = 'RESET_DELETE_PENGAJAR'

const actionTypes = Immutable({
  FETCH_PENGAJAR,
  FETCH_PENGAJAR_START,
  FETCH_PENGAJAR_END,
  FETCH_PENGAJAR_SUCCESS,
  FETCH_PENGAJAR_FAILED,

  CREATE_PENGAJAR,
  CREATE_PENGAJAR_START,
  CREATE_PENGAJAR_END,
  CREATE_PENGAJAR_SUCCESS,
  CREATE_PENGAJAR_FAILED,

  UPDATE_PENGAJAR,
  UPDATE_PENGAJAR_START,
  UPDATE_PENGAJAR_END,
  UPDATE_PENGAJAR_SUCCESS,
  UPDATE_PENGAJAR_FAILED,

  DELETE_PENGAJAR,
  DELETE_PENGAJAR_START,
  DELETE_PENGAJAR_END,
  DELETE_PENGAJAR_SUCCESS,
  DELETE_PENGAJAR_FAILED,

  RESET_FORM_PENGAJAR,
  RESET_DELETE_PENGAJAR
})

export default actionTypes
