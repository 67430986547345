import React from "react"

const SortTable = ({ sort, field }) => {

    return <React.Fragment>
        {
            sort?.by === field ?
                sort?.type === "asc" ?
                    < i className="bx bx-chevron-up"></i>
                    :
                    < i className="bx bx-chevron-down"></i>
                :
                null
        }
    </React.Fragment>
}

export default SortTable