import actionTypes from './actionTypes'

export const actionFetchPertemuan = (data) => {
  return {
    type: actionTypes.FETCH_PERTEMUAN,
    data
  }
}

export const actionFetchPertemuanStart = () => {
  return {
    type: actionTypes.FETCH_PERTEMUAN_START
  }
}

export const actionFetchPertemuanEnd = () => {
  return {
    type: actionTypes.FETCH_PERTEMUAN_END
  }
}

export const actionFetchPertemuanSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PERTEMUAN_SUCCESS,
    data
  }
}

export const actionCreatePertemuan = (data) => {
  return {
    type: actionTypes.CREATE_PERTEMUAN,
    data
  }
}

export const actionCreatePertemuanStart = () => {
  return {
    type: actionTypes.CREATE_PERTEMUAN_START
  }
}

export const actionCreatePertemuanEnd = () => {
  return {
    type: actionTypes.CREATE_PERTEMUAN_END
  }
}

export const actionCreatePertemuanSuccess = (data) => {
  return {
    type: actionTypes.CREATE_PERTEMUAN_SUCCESS,
    data
  }
}

export const actionCreatePertemuanFailed = (data) => {
  return {
    type: actionTypes.CREATE_PERTEMUAN_FAILED,
    data
  }
}

export const actionUpdatePertemuan = (data) => {
  return {
    type: actionTypes.UPDATE_PERTEMUAN,
    data
  }
}

export const actionUpdatePertemuanStart = () => {
  return {
    type: actionTypes.UPDATE_PERTEMUAN_START
  }
}

export const actionUpdatePertemuanEnd = () => {
  return {
    type: actionTypes.UPDATE_PERTEMUAN_END
  }
}

export const actionUpdatePertemuanSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_PERTEMUAN_SUCCESS,
    data
  }
}

export const actionUpdatePertemuanFailed = (data) => {
  return {
    type: actionTypes.UPDATE_PERTEMUAN_FAILED,
    data
  }
}

export const actionResetFormPertemuan = () => {
  return {
    type: actionTypes.RESET_FORM_PERTEMUAN
  }
}

export const actionDeletePertemuan = (data) => {
  return {
    type: actionTypes.DELETE_PERTEMUAN,
    data
  }
}

export const actionDeletePertemuanStart = () => {
  return {
    type: actionTypes.DELETE_PERTEMUAN_START
  }
}

export const actionDeletePertemuanEnd = () => {
  return {
    type: actionTypes.DELETE_PERTEMUAN_END
  }
}

export const actionDeletePertemuanSuccess = () => {
  return {
    type: actionTypes.DELETE_PERTEMUAN_SUCCESS
  }
}

export const actionDeletePertemuanFailed = () => {
  return {
    type: actionTypes.DELETE_PERTEMUAN_FAILED
  }
}

export const actionResetDeletePertemuan = () => {
  return {
    type: actionTypes.RESET_DELETE_PERTEMUAN
  }
}