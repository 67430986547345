import Immutable from 'seamless-immutable'

const FETCH_RANGE_NILAI = 'FETCH_RANGE_NILAI'
const FETCH_RANGE_NILAI_START = 'FETCH_RANGE_NILAI_START'
const FETCH_RANGE_NILAI_END = 'FETCH_RANGE_NILAI_END'
const FETCH_RANGE_NILAI_SUCCESS = 'FETCH_RANGE_NILAI_SUCCESS'
const FETCH_RANGE_NILAI_FAILED = 'FETCH_RANGE_NILAI_FAILED'

const CREATE_RANGE_NILAI = 'CREATE_RANGE_NILAI'
const CREATE_RANGE_NILAI_START = 'CREATE_RANGE_NILAI_START'
const CREATE_RANGE_NILAI_END = 'CREATE_RANGE_NILAI_END'
const CREATE_RANGE_NILAI_SUCCESS = 'CREATE_RANGE_NILAI_SUCCESS'
const CREATE_RANGE_NILAI_FAILED = 'CREATE_RANGE_NILAI_FAILED'

const UPDATE_RANGE_NILAI = 'UPDATE_RANGE_NILAI'
const UPDATE_RANGE_NILAI_START = 'UPDATE_RANGE_NILAI_START'
const UPDATE_RANGE_NILAI_END = 'UPDATE_RANGE_NILAI_END'
const UPDATE_RANGE_NILAI_SUCCESS = 'UPDATE_RANGE_NILAI_SUCCESS'
const UPDATE_RANGE_NILAI_FAILED = 'UPDATE_RANGE_NILAI_FAILED'

const DELETE_RANGE_NILAI = 'DELETE_RANGE_NILAI'
const DELETE_RANGE_NILAI_START = 'DELETE_RANGE_NILAI_START'
const DELETE_RANGE_NILAI_END = 'DELETE_RANGE_NILAI_END'
const DELETE_RANGE_NILAI_SUCCESS = 'DELETE_RANGE_NILAI_SUCCESS'
const DELETE_RANGE_NILAI_FAILED = 'DELETE_RANGE_NILAI_FAILED'

const RESET_FORM_RANGE_NILAI = 'RESET_FORM_RANGE_NILAI'
const RESET_DELETE_RANGE_NILAI = 'RESET_DELETE_RANGE_NILAI'

const actionTypes = Immutable({
  FETCH_RANGE_NILAI,
  FETCH_RANGE_NILAI_START,
  FETCH_RANGE_NILAI_END,
  FETCH_RANGE_NILAI_SUCCESS,
  FETCH_RANGE_NILAI_FAILED,

  CREATE_RANGE_NILAI,
  CREATE_RANGE_NILAI_START,
  CREATE_RANGE_NILAI_END,
  CREATE_RANGE_NILAI_SUCCESS,
  CREATE_RANGE_NILAI_FAILED,

  UPDATE_RANGE_NILAI,
  UPDATE_RANGE_NILAI_START,
  UPDATE_RANGE_NILAI_END,
  UPDATE_RANGE_NILAI_SUCCESS,
  UPDATE_RANGE_NILAI_FAILED,

  DELETE_RANGE_NILAI,
  DELETE_RANGE_NILAI_START,
  DELETE_RANGE_NILAI_END,
  DELETE_RANGE_NILAI_SUCCESS,
  DELETE_RANGE_NILAI_FAILED,

  RESET_FORM_RANGE_NILAI,
  RESET_DELETE_RANGE_NILAI
})

export default actionTypes
