import { all } from 'redux-saga/effects'
import authSaga from './auth/saga'
import notifikasiSaga from './notifikasi/saga'

import pengajarSaga from './pengajar/saga'
import pelajarSaga from './pelajar/saga'
import kelompokSaga from './kelompok/saga'
import tesSaga from './tes/saga'
import rangeNilaiSaga from './range-nilai/saga'

import kursusSaga from './kursus/saga'
import modulSaga from './modul/saga'
import pertemuanSaga from './pertemuan/saga'

import laporanTugasPelajarSaga from './laporan-tugas-pelajar/saga'
import laporanCapaianPelajarSaga from './laporan-capaian-pelajar/saga'

export default function* rootSaga() {
  yield all([
    authSaga(),
    notifikasiSaga(),

    pengajarSaga(),
    pelajarSaga(),
    kelompokSaga(),
    tesSaga(),
    rangeNilaiSaga(),
    
    kursusSaga(),
    modulSaga(),
    pertemuanSaga(),
    
    laporanTugasPelajarSaga(),
    laporanCapaianPelajarSaga(),
  ])
}