import React from "react"

const AvatarByName = ({ name }) => {
    return <React.Fragment>
        <div className="w-px-40 h-auto rounded-circle avatar-by-name" >
            {name}
        </div>
    </React.Fragment>
}

export default AvatarByName