import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionCreatePengajar, actionResetFormPengajar } from "../../store/pengajar/actions"
import { alert } from "../../utils/alert"
import { pushUrl } from "../../utils/url"
import Dropdown from "../../components/Form/Dropdown"
import { request } from "../../utils/request"
import NumberInput from "../../components/Form/NumberInput"
import TextInput from "../../components/Form/TextInput"
import PasswordInput from "../../components/Form/PasswordInput"

const PengajarCreate = (props) => {
    const { actionCreatePengajar, actionResetFormPengajar, accessToken, form } = props

    const [payload, setPayload] = useState({
        name: "",
        email: "",
        username: "",
        password: "",
        status: ""
    })
    const [errors, setErrors] = useState({})
    const [submit, setSubmit] = useState(false)

    useEffect(() => {
        const titlePage = "Tambah Pengajar"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        actionResetFormPengajar()

        return () => actionResetFormPengajar()
    }, [])

    useEffect(async () => {
        if (submit === true) {
            if (form?.success === true) {
                await alert({ title: "Berhasil", html: form?.message, icon: "success" })
                pushUrl("/pengajar")
            } else if (form?.success === false) {
                await alert({ title: "Gagal", html: form?.message, icon: "error" })
            }
        }
    }, [form?.success, submit])

    const onChange = ((field, value) => {
        payload[field] = value

        setPayload({
            ...payload,
        })
    })

    const onSubmit = (e) => {
        if (validate() === true) {
            setSubmit(true)

            actionCreatePengajar({
                payload,
                accessToken
            })
        }

        e.preventDefault()
    }

    const validate = () => {
        let jsonErrors = {}
        let hasError = false

        if (!payload?.name || payload?.name === "") {
            hasError = true
            jsonErrors["name"] = "Nama harus diisi"
        }
        if (!payload?.username || payload?.username === "") {
            hasError = true
            jsonErrors["username"] = "Username harus diisi"
        }
        if (!payload?.email || payload?.email === "") {
            hasError = true
            jsonErrors["email"] = "Email harus diisi"
        }
        if (!payload?.password || payload?.password === "") {
            hasError = true
            jsonErrors["password"] = "Kata sandi harus diisi"
        }
        if (!payload?.status || payload?.status === "") {
            hasError = true
            jsonErrors["status"] = "Status harus diisi"
        }

        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <Link to={process.env.REACT_APP_SUBDIR + "/pengajar"} className="btn btn-white mb-2 btn-md-block">Kembali</Link>

            <div className="card mt-3">
                <div className="card-body">
                    <form onSubmit={onSubmit} >
                        <div className="row">
                            <div className="col-lg-12 col-xl-10 col-xxl-8">
                                <TextInput
                                    id={"name"}
                                    name={"name"}
                                    placeholder={"Masukkan nama pengajar"}
                                    label={"Nama"}
                                    onChange={(e) => onChange('name', e?.target?.value)}
                                    required={true}
                                    value={payload?.name}
                                    error={errors?.name}
                                    errors={form?.errors?.name}
                                    tabIndex={1} />

                                <TextInput
                                    id={"username"}
                                    name={"username"}
                                    placeholder={"Masukkan username pengajar"}
                                    label={"Username"}
                                    onChange={(e) => onChange('username', e?.target?.value)}
                                    required={true}
                                    value={payload?.username}
                                    error={errors?.username}
                                    errors={form?.errors?.username}
                                    tabIndex={2} />

                                <TextInput
                                    id={"email"}
                                    name={"email"}
                                    placeholder={"Masukkan email pengajar"}
                                    label={"Email"}
                                    onChange={(e) => onChange('email', e?.target?.value)}
                                    required={true}
                                    value={payload?.email}
                                    error={errors?.email}
                                    errors={form?.errors?.email}
                                    tabIndex={3}
                                    type={"email"} />

                                <PasswordInput
                                    id={"password"}
                                    name={"password"}
                                    placeholder={"Masukkan kata sandi pengajar"}
                                    label={"Kata Sandi"}
                                    onChange={(e) => onChange('password', e?.target?.value)}
                                    required={true}
                                    value={payload?.password}
                                    error={errors?.password}
                                    errors={form?.errors?.password}
                                    tabIndex={4}
                                     />

                                <Dropdown
                                    id={"status"}
                                    name={"status"}
                                    placeholder={"Pilih status"}
                                    label={"Status"}
                                    onChange={(e) => onChange('status', e?.target?.value)}
                                    required={true}
                                    value={payload?.status}
                                    error={errors?.status}
                                    errors={form?.errors?.status}
                                    tabIndex={5}
                                    options={[
                                        { label: "Aktif", value: "Aktif" },
                                        { label: "Tidak Aktif", value: "Tidak Aktif" }
                                    ]} />

                                <button
                                    tabIndex={6}
                                    type="submit" className="btn btn-primary btn-md-block" disabled={form?.loading === true}>
                                    Kirim
                                    {form?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken }, pengajar: { form } } = state

    return { accessToken, form }
}

export default withRouter(connect(mapStateToProps, { actionCreatePengajar, actionResetFormPengajar })(PengajarCreate))