import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import AuthLayout from "./components/Layout/AuthLayout"
import NonAuthLayout from "./components/Layout/NonAuthLayout"
import ErrorLayout from "./components/Layout/ErrorLayout"
import { useSelector } from 'react-redux'
import Page404 from './pages/Error/Page404'
import SignIn from './pages/SignIn'
import SignOut from './pages/SignOut'
import Beranda from './pages/Beranda'

import PengajarIndex from './pages/Pengajar/index'
import PengajarCreate from './pages/Pengajar/create'
import PengajarDetail from './pages/Pengajar/detail'
import PengajarUpdate from './pages/Pengajar/update'

import PelajarIndex from './pages/Pelajar/index'
import PelajarCreate from './pages/Pelajar/create'
import PelajarDetail from './pages/Pelajar/detail'
import PelajarUpdate from './pages/Pelajar/update'

import KelompokIndex from './pages/Kelompok/index'
import KelompokCreate from './pages/Kelompok/create'
import KelompokDetail from './pages/Kelompok/detail'
import KelompokUpdate from './pages/Kelompok/update'

import RangeNilaiIndex from './pages/RangeNilai/index'
import RangeNilaiCreate from './pages/RangeNilai/create'
import RangeNilaiDetail from './pages/RangeNilai/detail'
import RangeNilaiUpdate from './pages/RangeNilai/update'

import TesIndex from './pages/Tes/index'
import TesCreate from './pages/Tes/create'
import TesDetail from './pages/Tes/detail'
import TesUpdate from './pages/Tes/update'

import KursusIndex from './pages/Kursus/index'
import ModulIndex from './pages/Modul/index'
import PertemuanIndex from './pages/Pertemuan/index'

import LaporanTugasPelajarIndex from './pages/LaporanTugasPelajar/index'
import LaporanCapaianPelajarIndex from './pages/LaporanCapaianPelajar/index'

const App = ({ childProps }) => {
  return (
    <Switch>
      <Route exact path={"/"} render={() => <Redirect to={process.env.REACT_APP_SUBDIR + '/beranda'} />} />
      <Route exact path={process.env.REACT_APP_SUBDIR + "/"} render={() => <Redirect to={process.env.REACT_APP_SUBDIR + '/beranda'} />} />

      <NonAuthRoute path={process.env.REACT_APP_SUBDIR + "/sign-in"} component={SignIn} props={childProps} />

      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/beranda"} component={Beranda} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/pengajar"} component={PengajarIndex} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/pengajar/create"} component={PengajarCreate} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/pengajar/detail/:id"} component={PengajarDetail} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/pengajar/update/:id"} component={PengajarUpdate} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/Pelajar"} component={PelajarIndex} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/Pelajar/create"} component={PelajarCreate} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/Pelajar/detail/:id"} component={PelajarDetail} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/Pelajar/update/:id"} component={PelajarUpdate} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/kelompok"} component={KelompokIndex} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/kelompok/create"} component={KelompokCreate} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/kelompok/detail/:id"} component={KelompokDetail} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/kelompok/update/:id"} component={KelompokUpdate} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/range-nilai"} component={RangeNilaiIndex} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/range-nilai/create"} component={RangeNilaiCreate} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/range-nilai/detail/:id"} component={RangeNilaiDetail} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/range-nilai/update/:id"} component={RangeNilaiUpdate} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/tes"} component={TesIndex} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/tes/create"} component={TesCreate} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/tes/detail/:id"} component={TesDetail} props={childProps} />
      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/tes/update/:id"} component={TesUpdate} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/kursus"} component={KursusIndex} props={childProps} />
      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/modul"} component={ModulIndex} props={childProps} />
      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/pertemuan"} component={PertemuanIndex} props={childProps} />

      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/laporan-tugas-pelajar"} component={LaporanTugasPelajarIndex} props={childProps} />
      <AuthRoute exact path={process.env.REACT_APP_SUBDIR + "/laporan-capaian-pelajar"} component={LaporanCapaianPelajarIndex} props={childProps} />

      <AuthRoute path={process.env.REACT_APP_SUBDIR + "/sign-out"} component={SignOut} props={childProps} />

      <ErrorRoute path='*' exact={true} component={Page404} />
    </Switch>
  )
}

const ErrorRoute = ({ component: Component, location, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <ErrorLayout>
          <Component {...props} />
        </ErrorLayout>
      )}
    />
  )
}

const NonAuthRoute = ({ component: Component, location, ...rest }) => {
  const auth = useSelector(state => state.auth)
  let pass = false

  if (auth?.user) {
    const now = parseInt((new Date).getTime() / 1000)
    const sisaWaktu = auth?.expiredAt - now

    if (sisaWaktu > 0) {
      pass = true
    }
  }

  return (
    <Route
      {...rest}
      render={props => (
        pass === true ?
          // <NonAuthLayout>
          //   <Component {...props} />
          // </NonAuthLayout>
          <Redirect
            to={{
              pathname: process.env.REACT_APP_SUBDIR + '/beranda',
              state: { from: props.location }
            }}
          />
          :
          <NonAuthLayout>
            <Component {...props} />
          </NonAuthLayout>
      )}
    />
  )
}

const AuthRoute = ({ component: Component, location, ...rest }) => {
  const auth = useSelector(state => state.auth)
  let pass = false

  if (auth && auth.user) {
    pass = true
  }

  return (
    <Route
      {...rest}
      render={props => (
        pass === true ?
          <AuthLayout>
            <Component {...props} />
          </AuthLayout>
          :
          <Redirect
            to={{
              pathname: process.env.REACT_APP_SUBDIR + '/sign-in',
              state: { from: props.location }
            }} />
      )}
    />
  )
}

export default App