import actionTypes from './actionTypes'

export const actionFetchTes = (data) => {
  return {
    type: actionTypes.FETCH_TES,
    data
  }
}

export const actionFetchTesStart = () => {
  return {
    type: actionTypes.FETCH_TES_START
  }
}

export const actionFetchTesEnd = () => {
  return {
    type: actionTypes.FETCH_TES_END
  }
}

export const actionFetchTesSuccess = (data) => {
  return {
    type: actionTypes.FETCH_TES_SUCCESS,
    data
  }
}

export const actionCreateTes = (data) => {
  return {
    type: actionTypes.CREATE_TES,
    data
  }
}

export const actionCreateTesStart = () => {
  return {
    type: actionTypes.CREATE_TES_START
  }
}

export const actionCreateTesEnd = () => {
  return {
    type: actionTypes.CREATE_TES_END
  }
}

export const actionCreateTesSuccess = (data) => {
  return {
    type: actionTypes.CREATE_TES_SUCCESS,
    data
  }
}

export const actionCreateTesFailed = (data) => {
  return {
    type: actionTypes.CREATE_TES_FAILED,
    data
  }
}

export const actionUpdateTes = (data) => {
  return {
    type: actionTypes.UPDATE_TES,
    data
  }
}

export const actionUpdateTesStart = () => {
  return {
    type: actionTypes.UPDATE_TES_START
  }
}

export const actionUpdateTesEnd = () => {
  return {
    type: actionTypes.UPDATE_TES_END
  }
}

export const actionUpdateTesSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_TES_SUCCESS,
    data
  }
}

export const actionUpdateTesFailed = (data) => {
  return {
    type: actionTypes.UPDATE_TES_FAILED,
    data
  }
}

export const actionResetFormTes = () => {
  return {
    type: actionTypes.RESET_FORM_TES
  }
}

export const actionDeleteTes = (data) => {
  return {
    type: actionTypes.DELETE_TES,
    data
  }
}

export const actionDeleteTesStart = () => {
  return {
    type: actionTypes.DELETE_TES_START
  }
}

export const actionDeleteTesEnd = () => {
  return {
    type: actionTypes.DELETE_TES_END
  }
}

export const actionDeleteTesSuccess = () => {
  return {
    type: actionTypes.DELETE_TES_SUCCESS
  }
}

export const actionDeleteTesFailed = () => {
  return {
    type: actionTypes.DELETE_TES_FAILED
  }
}

export const actionResetDeleteTes = () => {
  return {
    type: actionTypes.RESET_DELETE_TES
  }
}

export const actionImportTes = (data) => {
  return {
    type: actionTypes.IMPORT_TES,
    data
  }
}

export const actionImportTesStart = () => {
  return {
    type: actionTypes.IMPORT_TES_START
  }
}

export const actionImportTesEnd = () => {
  return {
    type: actionTypes.IMPORT_TES_END
  }
}

export const actionImportTesSuccess = (data) => {
  return {
    type: actionTypes.IMPORT_TES_SUCCESS,
    data
  }
}

export const actionImportTesFailed = (data) => {
  return {
    type: actionTypes.IMPORT_TES_FAILED,
    data
  }
}