import React, { useEffect, useState } from "react"
import Select, { components } from 'react-select'
import IcDropdown from "../../../assets/svg/dropdown-arrow.svg"
import { PRIMARY_COLOR } from "../../../constants"

const SelectSearch = ({ id = null, name, value, className = "", error = null, errors = null, hint = null, placeholder = "Pilih", label = null, onChange, required = false, tabIndex = null, options, disabled = false }) => {
    const [defaultValue, setDefaultValue] = useState(null)

    useEffect(() => {
        if (options?.length > 0 && value != null) {
            let selectedOption = options?.filter(val => {
                return val.value == value
            })

            if (selectedOption?.length > 0) {
                setDefaultValue(selectedOption[0])
            }
        }
    }, [value, options])

    return <React.Fragment>
        <div className="mb-3">
            <label htmlFor={id} className="form-label">
                {label}
                {required === true ? <span className="text-danger"> *</span> : null}
            </label>

            <Select
                id={id}
                type="text"
                className={"searchable " + (error || errors ? "is-invalid" : "") + className}
                placeholder={placeholder}
                aria-label={label}
                aria-describedby={label}
                name={name}
                defaultValue={defaultValue}
                value={defaultValue}
                onChange={onChange}
                options={options}
                tabIndex={tabIndex}
                isSearchable={true}
                isDisabled={disabled}
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: (props) => {
                        return (
                            <components.DropdownIndicator {...props}>
                                <img src={IcDropdown} width="13px" height="13px" alt="" />
                            </components.DropdownIndicator>
                        )
                    }
                }}
                styles={{
                    control: (provided, state) => ({
                        ...provided,
                        padding: "0rem 0.4rem 0rem 0.2rem",
                        boxShadow: "none",
                        border: ("1px solid " + (state.isFocused ? PRIMARY_COLOR : error ? "red" : "#d9dee3")),
                        borderRadius: 0,
                        '&:hover': {
                            border: ("1px solid " + (state.isFocused ? PRIMARY_COLOR : error ? "red" : "#d9dee3")),
                        }
                    }),
                    valueContainer: provided => ({
                        ...provided,
                        fontWeight: 400,
                        color: "#697a8d !important"
                    }),
                    input: provided => ({
                        ...provided,
                        fontWeight: 400,
                    }),
                    placeholder: provided => ({
                        ...provided,
                        fontSize: "0.9375rem",
                        color: "#697a8d",
                        fontWeight: 400
                    }),
                    dropdownIndicator: base => ({
                        ...base,
                        color: "#8e9baa"
                    })
                }}
            />
            {
                hint ? <div className="form-text">{hint}</div> : null
            }
            {
                error ? <div className="invalid-feedback">{error}</div> : null
            }
            {
                errors?.map((val, key) => {
                    return <div key={key} className="invalid-feedback">{val}</div>
                })
            }
        </div>
    </React.Fragment >
}

export default SelectSearch