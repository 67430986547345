import { combineReducers } from "redux"
import { connectRouter } from 'connected-react-router'
import history from '../utils/history'

import auth from "./auth/reducer"
import notifikasi from "./notifikasi/reducer"

import pengajar from "./pengajar/reducer"
import pelajar from "./pelajar/reducer"
import kelompok from "./kelompok/reducer"
import tes from "./tes/reducer"
import rangeNilai from "./range-nilai/reducer"

import kursus from "./kursus/reducer"
import modul from "./modul/reducer"
import pertemuan from "./pertemuan/reducer"
import laporanTugasPelajar from "./laporan-tugas-pelajar/reducer"
import laporanCapaianPelajar from "./laporan-capaian-pelajar/reducer"

const rootReducer = combineReducers({
  router: connectRouter(history),
  auth,
  notifikasi,
  
  kursus,
  modul,
  pertemuan,

  pengajar,
  pelajar,
  kelompok,
  tes,
  rangeNilai,
  
  laporanTugasPelajar,
  laporanCapaianPelajar,
})

export default rootReducer
