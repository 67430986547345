import { takeEvery, put, all, call, select } from 'redux-saga/effects'
import actionTypes from './actionTypes'
import { request } from '../../utils/request'
import { alert } from '../../utils/alert'
import { actionFetchNotifikasiSuccess } from './actions'

function* sagaFetchNotifikasi(params) {
  const { data: { accessToken } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_NOTIFIKASI}`
    const res = yield call(request, url, {
      method: 'GET',
      headers: header
    })

    if (res?.success === true) {
      yield put(actionFetchNotifikasiSuccess(res?.result))
    }
  } catch (err) { }
}

function* kategoriSoalSaga() {
  yield all([
    takeEvery(actionTypes.FETCH_NOTIFIKASI, sagaFetchNotifikasi)
  ])
}

export default kategoriSoalSaga