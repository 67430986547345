import React from "react"
import { Link } from "react-router-dom"

const Breadcrumbs = () => {
    return <React.Fragment>
        <div className="nav-item mb-0 d-md-block d-none">
            <Link to={"/beranda"} className="text-secondary" >App</Link>
            <i className="text-secondary bx bx-chevron-right pb-1px"></i>
            <span id="breadcrumbTitle" className="fw-bold text-primary">Beranda</span>
        </div>
    </React.Fragment>
}

export default Breadcrumbs