import Immutable from 'seamless-immutable'

const FETCH_PERTEMUAN = 'FETCH_PERTEMUAN'
const FETCH_PERTEMUAN_START = 'FETCH_PERTEMUAN_START'
const FETCH_PERTEMUAN_END = 'FETCH_PERTEMUAN_END'
const FETCH_PERTEMUAN_SUCCESS = 'FETCH_PERTEMUAN_SUCCESS'
const FETCH_PERTEMUAN_FAILED = 'FETCH_PERTEMUAN_FAILED'

const CREATE_PERTEMUAN = 'CREATE_PERTEMUAN'
const CREATE_PERTEMUAN_START = 'CREATE_PERTEMUAN_START'
const CREATE_PERTEMUAN_END = 'CREATE_PERTEMUAN_END'
const CREATE_PERTEMUAN_SUCCESS = 'CREATE_PERTEMUAN_SUCCESS'
const CREATE_PERTEMUAN_FAILED = 'CREATE_PERTEMUAN_FAILED'

const UPDATE_PERTEMUAN = 'UPDATE_PERTEMUAN'
const UPDATE_PERTEMUAN_START = 'UPDATE_PERTEMUAN_START'
const UPDATE_PERTEMUAN_END = 'UPDATE_PERTEMUAN_END'
const UPDATE_PERTEMUAN_SUCCESS = 'UPDATE_PERTEMUAN_SUCCESS'
const UPDATE_PERTEMUAN_FAILED = 'UPDATE_PERTEMUAN_FAILED'

const DELETE_PERTEMUAN = 'DELETE_PERTEMUAN'
const DELETE_PERTEMUAN_START = 'DELETE_PERTEMUAN_START'
const DELETE_PERTEMUAN_END = 'DELETE_PERTEMUAN_END'
const DELETE_PERTEMUAN_SUCCESS = 'DELETE_PERTEMUAN_SUCCESS'
const DELETE_PERTEMUAN_FAILED = 'DELETE_PERTEMUAN_FAILED'

const RESET_FORM_PERTEMUAN = 'RESET_FORM_PERTEMUAN'
const RESET_DELETE_PERTEMUAN = 'RESET_DELETE_PERTEMUAN'

const actionTypes = Immutable({
  FETCH_PERTEMUAN,
  FETCH_PERTEMUAN_START,
  FETCH_PERTEMUAN_END,
  FETCH_PERTEMUAN_SUCCESS,
  FETCH_PERTEMUAN_FAILED,

  CREATE_PERTEMUAN,
  CREATE_PERTEMUAN_START,
  CREATE_PERTEMUAN_END,
  CREATE_PERTEMUAN_SUCCESS,
  CREATE_PERTEMUAN_FAILED,

  UPDATE_PERTEMUAN,
  UPDATE_PERTEMUAN_START,
  UPDATE_PERTEMUAN_END,
  UPDATE_PERTEMUAN_SUCCESS,
  UPDATE_PERTEMUAN_FAILED,

  DELETE_PERTEMUAN,
  DELETE_PERTEMUAN_START,
  DELETE_PERTEMUAN_END,
  DELETE_PERTEMUAN_SUCCESS,
  DELETE_PERTEMUAN_FAILED,

  RESET_FORM_PERTEMUAN,
  RESET_DELETE_PERTEMUAN
})

export default actionTypes
