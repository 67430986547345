import actionTypes from './actionTypes'

export const actionFetchRangeNilai = (data) => {
  return {
    type: actionTypes.FETCH_RANGE_NILAI,
    data
  }
}

export const actionFetchRangeNilaiStart = () => {
  return {
    type: actionTypes.FETCH_RANGE_NILAI_START
  }
}

export const actionFetchRangeNilaiEnd = () => {
  return {
    type: actionTypes.FETCH_RANGE_NILAI_END
  }
}

export const actionFetchRangeNilaiSuccess = (data) => {
  return {
    type: actionTypes.FETCH_RANGE_NILAI_SUCCESS,
    data
  }
}

export const actionCreateRangeNilai = (data) => {
  return {
    type: actionTypes.CREATE_RANGE_NILAI,
    data
  }
}

export const actionCreateRangeNilaiStart = () => {
  return {
    type: actionTypes.CREATE_RANGE_NILAI_START
  }
}

export const actionCreateRangeNilaiEnd = () => {
  return {
    type: actionTypes.CREATE_RANGE_NILAI_END
  }
}

export const actionCreateRangeNilaiSuccess = (data) => {
  return {
    type: actionTypes.CREATE_RANGE_NILAI_SUCCESS,
    data
  }
}

export const actionCreateRangeNilaiFailed = (data) => {
  return {
    type: actionTypes.CREATE_RANGE_NILAI_FAILED,
    data
  }
}

export const actionUpdateRangeNilai = (data) => {
  return {
    type: actionTypes.UPDATE_RANGE_NILAI,
    data
  }
}

export const actionUpdateRangeNilaiStart = () => {
  return {
    type: actionTypes.UPDATE_RANGE_NILAI_START
  }
}

export const actionUpdateRangeNilaiEnd = () => {
  return {
    type: actionTypes.UPDATE_RANGE_NILAI_END
  }
}

export const actionUpdateRangeNilaiSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_RANGE_NILAI_SUCCESS,
    data
  }
}

export const actionUpdateRangeNilaiFailed = (data) => {
  return {
    type: actionTypes.UPDATE_RANGE_NILAI_FAILED,
    data
  }
}

export const actionResetFormRangeNilai = () => {
  return {
    type: actionTypes.RESET_FORM_RANGE_NILAI
  }
}

export const actionDeleteRangeNilai = (data) => {
  return {
    type: actionTypes.DELETE_RANGE_NILAI,
    data
  }
}

export const actionDeleteRangeNilaiStart = () => {
  return {
    type: actionTypes.DELETE_RANGE_NILAI_START
  }
}

export const actionDeleteRangeNilaiEnd = () => {
  return {
    type: actionTypes.DELETE_RANGE_NILAI_END
  }
}

export const actionDeleteRangeNilaiSuccess = () => {
  return {
    type: actionTypes.DELETE_RANGE_NILAI_SUCCESS
  }
}

export const actionDeleteRangeNilaiFailed = () => {
  return {
    type: actionTypes.DELETE_RANGE_NILAI_FAILED
  }
}

export const actionResetDeleteRangeNilai = () => {
  return {
    type: actionTypes.RESET_DELETE_RANGE_NILAI
  }
}