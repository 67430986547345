import Immutable from 'seamless-immutable'

const FETCH_KELOMPOK = 'FETCH_KELOMPOK'
const FETCH_KELOMPOK_START = 'FETCH_KELOMPOK_START'
const FETCH_KELOMPOK_END = 'FETCH_KELOMPOK_END'
const FETCH_KELOMPOK_SUCCESS = 'FETCH_KELOMPOK_SUCCESS'
const FETCH_KELOMPOK_FAILED = 'FETCH_KELOMPOK_FAILED'

const CREATE_KELOMPOK = 'CREATE_KELOMPOK'
const CREATE_KELOMPOK_START = 'CREATE_KELOMPOK_START'
const CREATE_KELOMPOK_END = 'CREATE_KELOMPOK_END'
const CREATE_KELOMPOK_SUCCESS = 'CREATE_KELOMPOK_SUCCESS'
const CREATE_KELOMPOK_FAILED = 'CREATE_KELOMPOK_FAILED'

const UPDATE_KELOMPOK = 'UPDATE_KELOMPOK'
const UPDATE_KELOMPOK_START = 'UPDATE_KELOMPOK_START'
const UPDATE_KELOMPOK_END = 'UPDATE_KELOMPOK_END'
const UPDATE_KELOMPOK_SUCCESS = 'UPDATE_KELOMPOK_SUCCESS'
const UPDATE_KELOMPOK_FAILED = 'UPDATE_KELOMPOK_FAILED'

const DELETE_KELOMPOK = 'DELETE_KELOMPOK'
const DELETE_KELOMPOK_START = 'DELETE_KELOMPOK_START'
const DELETE_KELOMPOK_END = 'DELETE_KELOMPOK_END'
const DELETE_KELOMPOK_SUCCESS = 'DELETE_KELOMPOK_SUCCESS'
const DELETE_KELOMPOK_FAILED = 'DELETE_KELOMPOK_FAILED'

const RESET_FORM_KELOMPOK = 'RESET_FORM_KELOMPOK'
const RESET_DELETE_KELOMPOK = 'RESET_DELETE_KELOMPOK'

const actionTypes = Immutable({
  FETCH_KELOMPOK,
  FETCH_KELOMPOK_START,
  FETCH_KELOMPOK_END,
  FETCH_KELOMPOK_SUCCESS,
  FETCH_KELOMPOK_FAILED,

  CREATE_KELOMPOK,
  CREATE_KELOMPOK_START,
  CREATE_KELOMPOK_END,
  CREATE_KELOMPOK_SUCCESS,
  CREATE_KELOMPOK_FAILED,

  UPDATE_KELOMPOK,
  UPDATE_KELOMPOK_START,
  UPDATE_KELOMPOK_END,
  UPDATE_KELOMPOK_SUCCESS,
  UPDATE_KELOMPOK_FAILED,

  DELETE_KELOMPOK,
  DELETE_KELOMPOK_START,
  DELETE_KELOMPOK_END,
  DELETE_KELOMPOK_SUCCESS,
  DELETE_KELOMPOK_FAILED,

  RESET_FORM_KELOMPOK,
  RESET_DELETE_KELOMPOK
})

export default actionTypes
