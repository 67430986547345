import actionTypes from './actionTypes'

export const actionFetchNotifikasi = (data) => {
  return {
    type: actionTypes.FETCH_NOTIFIKASI,
    data
  }
}

export const actionFetchNotifikasiSuccess = (data) => {
  return {
    type: actionTypes.FETCH_NOTIFIKASI_SUCCESS,
    data
  }
}