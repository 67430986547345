import React from "react"

const Dropdown = ({ id = null, name, value, className = "", error = null, errors = null, hint = null, placeholder = "Pilih", label = null, onChange, required = false, tabIndex = null, options, disabled }) => {
    return <React.Fragment>
        <div className="mb-3">
            <label htmlFor={id} className="form-label">
                {label}
                {required === true ? <span className="text-danger"> *</span> : null}
            </label>

            <select
                id={id}
                type="text"
                className={"form-select " + (error || errors ? "is-invalid" : "") + className}
                placeholder={placeholder}
                aria-label={label}
                aria-describedby={label}
                name={name}
                value={value}
                onChange={onChange}
                tabIndex={tabIndex}
                disabled={disabled}>
                <option value="">{placeholder}</option>
                {
                    options?.map((val, key) => {
                        return <option key={key} value={val?.value}>{val?.label}</option>
                    })
                }
            </select>
            {
                hint ? <div className="form-text">{hint}</div> : null
            }
            {
                error ? <div className="invalid-feedback">{error}</div> : null
            }
            {
                errors?.map((val, key) => {
                    return <div key={key} className="invalid-feedback">{val}</div>
                })
            }
        </div>
    </React.Fragment >
}

export default Dropdown