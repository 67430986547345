import actionTypes from './actionTypes'

export const actionFetchPelajar = (data) => {
  return {
    type: actionTypes.FETCH_PELAJAR,
    data
  }
}

export const actionFetchPelajarStart = () => {
  return {
    type: actionTypes.FETCH_PELAJAR_START
  }
}

export const actionFetchPelajarEnd = () => {
  return {
    type: actionTypes.FETCH_PELAJAR_END
  }
}

export const actionFetchPelajarSuccess = (data) => {
  return {
    type: actionTypes.FETCH_PELAJAR_SUCCESS,
    data
  }
}

export const actionCreatePelajar = (data) => {
  return {
    type: actionTypes.CREATE_PELAJAR,
    data
  }
}

export const actionCreatePelajarStart = () => {
  return {
    type: actionTypes.CREATE_PELAJAR_START
  }
}

export const actionCreatePelajarEnd = () => {
  return {
    type: actionTypes.CREATE_PELAJAR_END
  }
}

export const actionCreatePelajarSuccess = (data) => {
  return {
    type: actionTypes.CREATE_PELAJAR_SUCCESS,
    data
  }
}

export const actionCreatePelajarFailed = (data) => {
  return {
    type: actionTypes.CREATE_PELAJAR_FAILED,
    data
  }
}

export const actionUpdatePelajar = (data) => {
  return {
    type: actionTypes.UPDATE_PELAJAR,
    data
  }
}

export const actionUpdatePelajarStart = () => {
  return {
    type: actionTypes.UPDATE_PELAJAR_START
  }
}

export const actionUpdatePelajarEnd = () => {
  return {
    type: actionTypes.UPDATE_PELAJAR_END
  }
}

export const actionUpdatePelajarSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_PELAJAR_SUCCESS,
    data
  }
}

export const actionUpdatePelajarFailed = (data) => {
  return {
    type: actionTypes.UPDATE_PELAJAR_FAILED,
    data
  }
}

export const actionResetFormPelajar = () => {
  return {
    type: actionTypes.RESET_FORM_PELAJAR
  }
}

export const actionDeletePelajar = (data) => {
  return {
    type: actionTypes.DELETE_PELAJAR,
    data
  }
}

export const actionDeletePelajarStart = () => {
  return {
    type: actionTypes.DELETE_PELAJAR_START
  }
}

export const actionDeletePelajarEnd = () => {
  return {
    type: actionTypes.DELETE_PELAJAR_END
  }
}

export const actionDeletePelajarSuccess = () => {
  return {
    type: actionTypes.DELETE_PELAJAR_SUCCESS
  }
}

export const actionDeletePelajarFailed = () => {
  return {
    type: actionTypes.DELETE_PELAJAR_FAILED
  }
}

export const actionResetDeletePelajar = () => {
  return {
    type: actionTypes.RESET_DELETE_PELAJAR
  }
}