import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionUpdateKelompok, actionResetFormKelompok } from "../../store/kelompok/actions"
import { alert } from "../../utils/alert"
import { pushUrl } from "../../utils/url"
import Dropdown from "../../components/Form/Dropdown"
import { request } from "../../utils/request"
import NumberInput from "../../components/Form/NumberInput"
import TextInput from "../../components/Form/TextInput"

const KelompokUpdate = (props) => {
    const { actionUpdateKelompok, actionResetFormKelompok, accessToken, match: { params: { id } }, history: { location: { state } }, form } = props

    const [payload, setPayload] = useState({
        nama: "",
        status: ""
    })
    const [errors, setErrors] = useState({})
    const [submit, setSubmit] = useState(false)
    const [noData, setNoData] = useState(false)
    const [from, setFrom] = useState("/kelompok")

    useEffect(() => {
        const titlePage = "Ubah Kelompok"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)

        if (state) {
            setPayload(state)
        } else {
            getData()
        }

        const query = new URLSearchParams(props.location.search)
        if (query.get('from') === "detail") {
            setFrom("/kelompok/detail/" + id)
        }

        actionResetFormKelompok()

        return () => actionResetFormKelompok()
    }, [])

    const getData = async () => {
        try {
            const header = {
                "Authorization": `Bearer ${accessToken}`,
            }
            const res = await request(process.env.REACT_APP_SERVICE_KELOMPOK + '/' + id, {
                method: 'GET',
                headers: header
            })

            if (res?.success === true) {
                setPayload(res?.result)
            } else {
                setNoData(true)
            }
        } catch (error) {
            setNoData(true)
            alert({ title: "Gagal", html: "Gagal meminta data ke server" })
        }
    }

    useEffect(async () => {
        if (submit === true) {
            if (form?.success === true) {
                await alert({ title: "Berhasil", html: form?.message, icon: "success" })
                pushUrl(from)
            } else if (form?.success === false) {
                await alert({ title: "Gagal", html: form?.message, icon: "error" })
            }
        }
    }, [form?.success, submit])

    const onChange = ((field, value) => {
        payload[field] = value

        setPayload({
            ...payload,
        })
    })

    const onSubmit = (e) => {
        if (validate() === true) {
            setSubmit(true)

            actionUpdateKelompok({
                id,
                payload,
                accessToken
            })
        }

        e.preventDefault()
    }

    const validate = () => {
        let jsonErrors = {}
        let hasError = false

        if (!payload?.nama || payload?.nama === "") {
            hasError = true
            jsonErrors["nama"] = "Nama harus diisi"
        }
        if (!payload?.status || payload?.status === "") {
            hasError = true
            jsonErrors["status"] = "Status harus diisi"
        }

        setErrors(jsonErrors)

        if (hasError === true) {
            return false
        } else {
            return true
        }
    }

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <Link to={process.env.REACT_APP_SUBDIR + from} className="btn btn-white mb-2 btn-md-block">Kembali</Link>

            <div className="card mt-3">
                <div className="card-body">
                    {
                        noData === true ?
                            <span>Data tidak ditemukan</span>
                            :
                            <form onSubmit={onSubmit} >
                                <div className="row">
                                    <div className="col-lg-12 col-xl-10 col-xxl-8">
                                        <TextInput
                                            id={"nama"}
                                            name={"nama"}
                                            placeholder={"Masukkan nama kelompok"}
                                            label={"Nama"}
                                            onChange={(e) => onChange('nama', e?.target?.value)}
                                            required={true}
                                            value={payload?.nama}
                                            error={errors?.nama}
                                            errors={form?.errors?.nama}
                                            tabIndex={1} />

                                        <Dropdown
                                            id={"status"}
                                            name={"status"}
                                            placeholder={"Pilih status"}
                                            label={"Status"}
                                            onChange={(e) => onChange('status', e?.target?.value)}
                                            required={true}
                                            value={payload?.status}
                                            error={errors?.status}
                                            errors={form?.errors?.status}
                                            tabIndex={2}
                                            options={[
                                                { label: "Aktif", value: "Aktif" },
                                                { label: "Tidak Aktif", value: "Tidak Aktif" }
                                            ]} />

                                        <button
                                            tabIndex={3}
                                            type="submit" className="btn btn-primary btn-md-block" disabled={form?.loading === true}>
                                            Kirim
                                            {form?.loading === true ? <span className="spinner-border ms-2" role="status" aria-hidden="true"></span> : null}
                                        </button>
                                    </div>
                                </div>
                            </form>
                    }
                </div>
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = state => {
    const { auth: { accessToken }, kelompok: { form } } = state

    return { accessToken, form }
}

export default withRouter(connect(mapStateToProps, { actionUpdateKelompok, actionResetFormKelompok })(KelompokUpdate))