import Immutable from 'seamless-immutable'

const FETCH_PELAJAR = 'FETCH_PELAJAR'
const FETCH_PELAJAR_START = 'FETCH_PELAJAR_START'
const FETCH_PELAJAR_END = 'FETCH_PELAJAR_END'
const FETCH_PELAJAR_SUCCESS = 'FETCH_PELAJAR_SUCCESS'
const FETCH_PELAJAR_FAILED = 'FETCH_PELAJAR_FAILED'

const CREATE_PELAJAR = 'CREATE_PELAJAR'
const CREATE_PELAJAR_START = 'CREATE_PELAJAR_START'
const CREATE_PELAJAR_END = 'CREATE_PELAJAR_END'
const CREATE_PELAJAR_SUCCESS = 'CREATE_PELAJAR_SUCCESS'
const CREATE_PELAJAR_FAILED = 'CREATE_PELAJAR_FAILED'

const UPDATE_PELAJAR = 'UPDATE_PELAJAR'
const UPDATE_PELAJAR_START = 'UPDATE_PELAJAR_START'
const UPDATE_PELAJAR_END = 'UPDATE_PELAJAR_END'
const UPDATE_PELAJAR_SUCCESS = 'UPDATE_PELAJAR_SUCCESS'
const UPDATE_PELAJAR_FAILED = 'UPDATE_PELAJAR_FAILED'

const DELETE_PELAJAR = 'DELETE_PELAJAR'
const DELETE_PELAJAR_START = 'DELETE_PELAJAR_START'
const DELETE_PELAJAR_END = 'DELETE_PELAJAR_END'
const DELETE_PELAJAR_SUCCESS = 'DELETE_PELAJAR_SUCCESS'
const DELETE_PELAJAR_FAILED = 'DELETE_PELAJAR_FAILED'

const RESET_FORM_PELAJAR = 'RESET_FORM_PELAJAR'
const RESET_DELETE_PELAJAR = 'RESET_DELETE_PELAJAR'

const actionTypes = Immutable({
  FETCH_PELAJAR,
  FETCH_PELAJAR_START,
  FETCH_PELAJAR_END,
  FETCH_PELAJAR_SUCCESS,
  FETCH_PELAJAR_FAILED,

  CREATE_PELAJAR,
  CREATE_PELAJAR_START,
  CREATE_PELAJAR_END,
  CREATE_PELAJAR_SUCCESS,
  CREATE_PELAJAR_FAILED,

  UPDATE_PELAJAR,
  UPDATE_PELAJAR_START,
  UPDATE_PELAJAR_END,
  UPDATE_PELAJAR_SUCCESS,
  UPDATE_PELAJAR_FAILED,

  DELETE_PELAJAR,
  DELETE_PELAJAR_START,
  DELETE_PELAJAR_END,
  DELETE_PELAJAR_SUCCESS,
  DELETE_PELAJAR_FAILED,

  RESET_FORM_PELAJAR,
  RESET_DELETE_PELAJAR
})

export default actionTypes
