import React from "react"

const LimitTable = ({ perPage, onChange }) => {
    return <React.Fragment>
        <div className="d-block d-lg-flex">
            <label className="me-2 justify-content-center align-self-center">Tampil</label>
            <select aria-controls="Maks" className="form-select" value={perPage} onChange={(e) => onChange(e?.target?.value)}>
                <option value={"5"}>5</option>
                <option value={"10"}>10</option>
                <option value={"25"}>25</option>
                <option value={"50"}>50</option>
                <option value={"100"}>100</option>
            </select>
        </div>
    </React.Fragment>
}

export default LimitTable