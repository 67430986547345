import React from 'react'
import { withRouter } from 'react-router-dom'
import ScrollToTop from '../../ScrollToTop'

const NonAuthLayout = (props) => {
    const { children } = props

    return (
        <React.Fragment>
            <ScrollToTop />

            <div className="authentication-wrapper authentication-cover">
                {children}
            </div>
        </React.Fragment>
    )
}

export default withRouter(NonAuthLayout)