function parseJSON(response) {
  return response.json()
}

function checkStatus(response) {
  if (response.status >= 200 && response.status <= 300) {
    return response
  }
  if (response.status === 401) {
    window.location.href = `${process.env.REACT_APP_SUBDIR}/sign-out`
    return response
  }
  const error = new Error(response.statusText)
  error.response = response
  throw error
}

export function request(url, options) {
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
}