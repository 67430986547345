import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Router } from 'react-router-dom'
import history from './utils/history'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import { store, persistor } from './store'
import { ConnectedRouter } from 'connected-react-router'

import './index.css'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <BrowserRouter>
          <Router history={history}>
            <App />
          </Router>
        </BrowserRouter>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

reportWebVitals()
