import React from "react"
import ReactQuill from "react-quill";
// import React, { useEffect, useState } from "react"
import "react-quill/dist/quill.snow.css";

const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image"
]

const modules = {
    toolbar: [
        // [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" }
        ],
        ["link", "image"],
        ["clean"]
    ]
}

const TextEditor = ({ id = null, name, value, className = "", error = null, errors = null, hint = null, placeholder = null, label = null, onChange, required = false, tabIndex = null }) => {
    return <React.Fragment>
        <div className={"mb-3"}>
            <label htmlFor={id} className="form-label">
                {label}
                {required === true ? <span className="text-danger"> *</span> : null}
            </label>

            <ReactQuill
                id={id}
                name={name}
                value={value}
                modules={modules}
                formats={formats}
                onChange={onChange}
                tabIndex={tabIndex}
                placeholder={placeholder}
                className={error || errors ? "error" : ""}
            />

            {
                hint ? <div className="form-text">{hint}</div> : null
            }
            {
                error ? <div className="invalid-feedback d-block">{error}</div> : null
            }
            {
                errors?.map((val, key) => {
                    return <div key={key} className="invalid-feedback d-block">{val}</div>
                })
            }
        </div>
    </React.Fragment >
}

export default TextEditor