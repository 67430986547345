import React from "react"

const SearchTable = ({ value, onSearch, onChange }) => {
    return <React.Fragment>
        <form onSubmit={onSearch}>
            <div className="cari input-group mb-2">
                <input type="text" value={value} onChange={(e) => onChange(e.target?.value)} className="form-control" placeholder="Cari data" aria-label="Cari data" aria-describedby="Cari" />
                <button className="btn btn-secondary" type="submit" id="cari">Telusuri</button>
            </div>
        </form>
    </React.Fragment>
}

export default SearchTable