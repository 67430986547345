import { takeEvery, put, all, call, select } from 'redux-saga/effects'
import actionTypes from './actionTypes'
import { request } from '../../utils/request'
import { alert } from '../../utils/alert'
import { actionUpdateRangeNilaiEnd, actionUpdateRangeNilaiFailed, actionUpdateRangeNilaiStart, actionUpdateRangeNilaiSuccess, actionCreateRangeNilaiEnd, actionCreateRangeNilaiFailed, actionCreateRangeNilaiStart, actionCreateRangeNilaiSuccess, actionFetchRangeNilaiEnd, actionFetchRangeNilaiStart, actionFetchRangeNilaiSuccess, actionDeleteRangeNilaiSuccess, actionDeleteRangeNilaiFailed, actionDeleteRangeNilaiStart, actionDeleteRangeNilaiEnd } from './actions'
import { pushUrl } from '../../utils/url'

function* sagaFetchRangeNilai(params) {
  yield put(actionFetchRangeNilaiStart())

  const { data: { accessToken, search, page, sort, perPage } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let sortParam = ""
    if (sort?.by) {
      sortParam += "&sortBy=" + sort?.by
    }
    if (sort?.type) {
      sortParam += "&sortType=" + sort?.type
    }

    let perPageParam = perPage ? "&perPage=" + perPage : ""

    let pageParam = page ? "?page=" + page : "?page=1"

    let searchParam = search ? "&search=" + search : ""

    let url = `${process.env.REACT_APP_SERVICE_RANGE_NILAI}${pageParam}${searchParam}${sortParam}${perPageParam}`
    const res = yield call(request, url, {
      method: 'GET',
      headers: header
    })

    if (res?.success === true) {
      let data = {
        list: res?.result?.data,
        page: res?.result?.current_page,
        pageUrl: {
          first_page_url: res?.result?.first_page_url,
          links: res?.result?.links,
          last_page_url: res?.result?.last_page_url
        },
        last_page: res?.result?.last_page,
        from: res?.result?.from,
        to: res?.result?.to,
        total: res?.result?.total
      }
      yield put(actionFetchRangeNilaiSuccess(data))
    } else {
      alert({ title: "Gagal", html: res?.message })
    }
  } catch (err) {
    alert({ title: "Gagal" })
    // yield put(actionSignInFailed())
  }

  yield put(actionFetchRangeNilaiEnd())
}

function* sagaCreateRangeNilai(params) {
  yield put(actionCreateRangeNilaiStart())

  const { data: { accessToken, payload } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_RANGE_NILAI}`
    const res = yield call(request, url, {
      method: 'POST',
      headers: headerPost,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionCreateRangeNilaiSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionCreateRangeNilaiFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal menambah data",
      errors: null
    }

    yield put(actionCreateRangeNilaiFailed(data))
  }

  yield put(actionCreateRangeNilaiEnd())
}

function* sagaUpdateRangeNilai(params) {
  yield put(actionUpdateRangeNilaiStart())

  const { data: { accessToken, payload, id } } = params
  const headerPost = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_RANGE_NILAI}/${id}`
    const res = yield call(request, url, {
      method: 'PUT',
      headers: headerPost,
      body: JSON.stringify(payload)
    })

    if (res?.success === true) {
      let data = {
        payload,
        message: res?.message,
        result: res?.result
      }

      yield put(actionUpdateRangeNilaiSuccess(data))
    } else {
      let data = {
        payload,
        message: res?.message,
        errors: res?.errors
      }

      yield put(actionUpdateRangeNilaiFailed(data))
    }
  } catch (err) {
    let data = {
      payload,
      message: "Gagal merubah data",
      errors: null
    }

    yield put(actionUpdateRangeNilaiFailed(data))
  }

  yield put(actionUpdateRangeNilaiEnd())
}

function* sagaDeleteRangeNilai(params) {
  yield put(actionDeleteRangeNilaiStart())

  const { data: { accessToken, id } } = params
  const header = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }

  try {
    let url = `${process.env.REACT_APP_SERVICE_RANGE_NILAI}/${id}`
    const res = yield call(request, url, {
      method: 'DELETE',
      headers: header
    })

    if (res?.success === true) {
      yield put(actionDeleteRangeNilaiSuccess())
    } else {
      yield put(actionDeleteRangeNilaiFailed())
    }
  } catch (err) {
    yield put(actionDeleteRangeNilaiFailed())
  }

  yield put(actionDeleteRangeNilaiEnd())
}

function* rangeNilaiSaga() {
  yield all([
    takeEvery(actionTypes.FETCH_RANGE_NILAI, sagaFetchRangeNilai),
    takeEvery(actionTypes.CREATE_RANGE_NILAI, sagaCreateRangeNilai),
    takeEvery(actionTypes.UPDATE_RANGE_NILAI, sagaUpdateRangeNilai),
    takeEvery(actionTypes.DELETE_RANGE_NILAI, sagaDeleteRangeNilai),
  ])
}

export default rangeNilaiSaga