import actionTypes from './actionTypes'

export const actionFetchKelompok = (data) => {
  return {
    type: actionTypes.FETCH_KELOMPOK,
    data
  }
}

export const actionFetchKelompokStart = () => {
  return {
    type: actionTypes.FETCH_KELOMPOK_START
  }
}

export const actionFetchKelompokEnd = () => {
  return {
    type: actionTypes.FETCH_KELOMPOK_END
  }
}

export const actionFetchKelompokSuccess = (data) => {
  return {
    type: actionTypes.FETCH_KELOMPOK_SUCCESS,
    data
  }
}

export const actionCreateKelompok = (data) => {
  return {
    type: actionTypes.CREATE_KELOMPOK,
    data
  }
}

export const actionCreateKelompokStart = () => {
  return {
    type: actionTypes.CREATE_KELOMPOK_START
  }
}

export const actionCreateKelompokEnd = () => {
  return {
    type: actionTypes.CREATE_KELOMPOK_END
  }
}

export const actionCreateKelompokSuccess = (data) => {
  return {
    type: actionTypes.CREATE_KELOMPOK_SUCCESS,
    data
  }
}

export const actionCreateKelompokFailed = (data) => {
  return {
    type: actionTypes.CREATE_KELOMPOK_FAILED,
    data
  }
}

export const actionUpdateKelompok = (data) => {
  return {
    type: actionTypes.UPDATE_KELOMPOK,
    data
  }
}

export const actionUpdateKelompokStart = () => {
  return {
    type: actionTypes.UPDATE_KELOMPOK_START
  }
}

export const actionUpdateKelompokEnd = () => {
  return {
    type: actionTypes.UPDATE_KELOMPOK_END
  }
}

export const actionUpdateKelompokSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_KELOMPOK_SUCCESS,
    data
  }
}

export const actionUpdateKelompokFailed = (data) => {
  return {
    type: actionTypes.UPDATE_KELOMPOK_FAILED,
    data
  }
}

export const actionResetFormKelompok = () => {
  return {
    type: actionTypes.RESET_FORM_KELOMPOK
  }
}

export const actionDeleteKelompok = (data) => {
  return {
    type: actionTypes.DELETE_KELOMPOK,
    data
  }
}

export const actionDeleteKelompokStart = () => {
  return {
    type: actionTypes.DELETE_KELOMPOK_START
  }
}

export const actionDeleteKelompokEnd = () => {
  return {
    type: actionTypes.DELETE_KELOMPOK_END
  }
}

export const actionDeleteKelompokSuccess = () => {
  return {
    type: actionTypes.DELETE_KELOMPOK_SUCCESS
  }
}

export const actionDeleteKelompokFailed = () => {
  return {
    type: actionTypes.DELETE_KELOMPOK_FAILED
  }
}

export const actionResetDeleteKelompok = () => {
  return {
    type: actionTypes.RESET_DELETE_KELOMPOK
  }
}