import Immutable from 'seamless-immutable'

const FETCH_MODUL = 'FETCH_MODUL'
const FETCH_MODUL_START = 'FETCH_MODUL_START'
const FETCH_MODUL_END = 'FETCH_MODUL_END'
const FETCH_MODUL_SUCCESS = 'FETCH_MODUL_SUCCESS'
const FETCH_MODUL_FAILED = 'FETCH_MODUL_FAILED'

const CREATE_MODUL = 'CREATE_MODUL'
const CREATE_MODUL_START = 'CREATE_MODUL_START'
const CREATE_MODUL_END = 'CREATE_MODUL_END'
const CREATE_MODUL_SUCCESS = 'CREATE_MODUL_SUCCESS'
const CREATE_MODUL_FAILED = 'CREATE_MODUL_FAILED'

const UPDATE_MODUL = 'UPDATE_MODUL'
const UPDATE_MODUL_START = 'UPDATE_MODUL_START'
const UPDATE_MODUL_END = 'UPDATE_MODUL_END'
const UPDATE_MODUL_SUCCESS = 'UPDATE_MODUL_SUCCESS'
const UPDATE_MODUL_FAILED = 'UPDATE_MODUL_FAILED'

const DELETE_MODUL = 'DELETE_MODUL'
const DELETE_MODUL_START = 'DELETE_MODUL_START'
const DELETE_MODUL_END = 'DELETE_MODUL_END'
const DELETE_MODUL_SUCCESS = 'DELETE_MODUL_SUCCESS'
const DELETE_MODUL_FAILED = 'DELETE_MODUL_FAILED'

const RESET_FORM_MODUL = 'RESET_FORM_MODUL'
const RESET_DELETE_MODUL = 'RESET_DELETE_MODUL'

const actionTypes = Immutable({
  FETCH_MODUL,
  FETCH_MODUL_START,
  FETCH_MODUL_END,
  FETCH_MODUL_SUCCESS,
  FETCH_MODUL_FAILED,

  CREATE_MODUL,
  CREATE_MODUL_START,
  CREATE_MODUL_END,
  CREATE_MODUL_SUCCESS,
  CREATE_MODUL_FAILED,

  UPDATE_MODUL,
  UPDATE_MODUL_START,
  UPDATE_MODUL_END,
  UPDATE_MODUL_SUCCESS,
  UPDATE_MODUL_FAILED,

  DELETE_MODUL,
  DELETE_MODUL_START,
  DELETE_MODUL_END,
  DELETE_MODUL_SUCCESS,
  DELETE_MODUL_FAILED,

  RESET_FORM_MODUL,
  RESET_DELETE_MODUL
})

export default actionTypes
