import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { changeBreadcrumbs, changeTitle } from "../../utils/page"
import { actionDeletePertemuan, actionFetchPertemuan } from "../../store/pertemuan/actions"
import Pagination from "../../components/Table/Pagination"
import { changeUrl } from "../../utils/url"
import { alert, confirm } from "../../utils/alert"
import LimitTable from "../../components/Table/LimitTable"
import SearchTable from "../../components/Table/SearchTable"
import HeadTable from "../../components/Table/HeadTable"

const PertemuanIndex = (props) => {
    const { actionFetchPertemuan, actionDeletePertemuan, accessToken, index, del } = props
    const [sort, setSort] = useState({
        by: null,
        type: null
    })
    const [search, setSearch] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState("")
    const [submit, setSubmit] = useState(false)

    useEffect(() => {
        const titlePage = "Pertemuan"
        changeTitle(titlePage)
        changeBreadcrumbs(titlePage)
        fetchData()
    }, [])

    const fetchData = () => {
        const query = new URLSearchParams(props.location.search)

        if (query.get('page')) {
            setPage(parseInt(query.get('page')))
        } else {
            setPage(1)
        }

        if (query.get('search')) {
            setSearch(query.get('search'))
            setSearchValue(query.get('search'))
        }

        if (query.get('perPage')) {
            setPerPage(parseInt(query.get('perPage')))
        } else {
            setPerPage(10)
        }

        if (query.get('sortBy') || query.get('sortType')) {
            setSort({
                by: query.get('sortBy') || null,
                type: query.get('sortType') || null,
            })
        }

        actionFetchPertemuan({
            search: query.get('search') || "",
            page: parseInt(query.get('page')) || 1,
            sort: {
                by: query.get('sortBy') || null,
                type: query.get('sortType') || null,
            },
            perPage: parseInt(query.get('perPage')) || 10,
            accessToken
        })
    }

    const classStatus = (status) => {
        if (status === "Aktif") {
            return "bg-label-success"
        } else if (status === "Tidak Aktif") {
            return "bg-label-danger"
        }

        return "bg-label-secondary"
    }

    const sortData = (field, e) => {
        let sortType = sort?.type === "desc" ? "asc" : "desc"
        setSort({
            by: field,
            type: sortType
        })
        actionFetchPertemuan({
            search,
            page,
            sort: {
                by: field,
                type: sortType
            },
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const changePerPage = (val) => {
        setPerPage(val)
        actionFetchPertemuan({
            search,
            page,
            sort,
            perPage: val,
            accessToken
        })
    }

    useEffect(() => {
        let queryParams = {}
        if (page) {
            queryParams['page'] = page
        }
        if (search) {
            queryParams['search'] = search
        }
        if (sort?.by) {
            queryParams['sortBy'] = sort?.by
        }
        if (sort?.type) {
            queryParams['sortType'] = sort?.type
        }
        if (perPage) {
            queryParams['perPage'] = perPage
        }

        let query = new URLSearchParams(queryParams).toString()

        changeUrl("pertemuan?" + query)
    }, [page, search, sort?.by, sort?.type, perPage])

    const pageData = (val, e) => {
        setPage(val)
        actionFetchPertemuan({
            search,
            page: val,
            sort,
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const onSearch = (e) => {
        setSearch(searchValue)
        actionFetchPertemuan({
            search: searchValue,
            page,
            sort,
            perPage,
            accessToken
        })
        e.preventDefault()
    }

    const onChangeSearch = (val) => {
        setSearchValue(val)
    }

    const deleteData = async (e, id, nama) => {
        e.preventDefault()

        let res = await confirm({
            title: "Hapus " + nama,
            html: "Apakah anda yakin menghapus data ini?"
        })
        if (res === true) {
            setSubmit(true)
            actionDeletePertemuan({ accessToken, id })
        }
    }

    useEffect(() => {
        if (submit === true) {
            if (del?.success === true) {
                actionFetchPertemuan({
                    search,
                    page,
                    sort,
                    perPage,
                    accessToken
                })
                alert({ title: "Berhasil hapus data" })
            } else if (del?.success === false) {
                alert({ title: "Gagal hapus data" })
            }
        }
    }, [del?.success, submit])

    useEffect(() => {
        if (page && index?.last_page) {
            if (page > index?.last_page) {
                setPage(index?.last_page)
                actionFetchPertemuan({
                    search,
                    page: index?.last_page,
                    sort,
                    perPage,
                    accessToken
                })
            } else if (page < 0) {
                setPage(1)
                actionFetchPertemuan({
                    search,
                    page: 1,
                    sort,
                    perPage,
                    accessToken
                })
            }
        }
    }, [page, index?.last_page])

    return <React.Fragment>
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-lg-8">
                </div>
                <div className="col-lg-4">
                    <SearchTable onSearch={onSearch} onChange={onChangeSearch} value={searchValue} />
                </div>
            </div>

            {
                index?.total > 0 ?
                    <p className="mt-2">Menampilkan {index?.from} - {index?.to} dari {index?.total} data</p>
                    :
                    <p className="mt-2">Menampilkan 0 data</p>
            }

            <div className="card mt-3">
                <div className="table-responsive grid-view">
                    <table className="table">
                        <HeadTable
                            sort={sort}
                            sortData={sortData}
                            columns={[
                                { type: "label", label: "No." },
                                { type: "sortable", label: "Nama Pertemuan", field: "nama" },
                                { type: "sortable", label: "Nama Modul / Kursus", field: "modul.nama" },
                                { type: "sortable", label: "Nama Pengajar", field: "updated_by" },
                                { type: "label", label: "Quiz Awal" },
                                { type: "label", label: "Jobsheet" },
                                { type: "label", label: "Gambar" },
                                { type: "label", label: "Video" },
                                { type: "label", label: "Quiz" },
                                { type: "label", label: "Tugas" },
                                { type: "sortable", label: "Status", field: "status" },
                            ]}
                        />
                        <tbody className="table-border-bottom-0">
                            {
                                index?.list?.length > 0 ?
                                    index?.list?.map((val, key) => {
                                        return <tr key={key}>
                                            <td><strong>{((page - 1) * perPage) + key + 1}</strong></td>
                                            <td>{val?.nama}</td>
                                            <td>{val?.modul?.nama} <br /> <span className="text-muted">{val?.modul?.kursus?.nama}</span></td>
                                            <td>{val?.updated_by?.name}</td>
                                            <td>{val?.jumlah_quiz_awal || 0}</td>
                                            <td>{val?.jumlah_jobsheet || 0}</td>
                                            <td>{val?.jumlah_gambar || 0}</td>
                                            <td>{val?.jumlah_video || 0}</td>
                                            <td>{val?.jumlah_quiz || 0}</td>
                                            <td>{val?.jumlah_laporan || 0}</td>
                                            <td><span className={"badge me-1 " + classStatus(val?.status)}>{val?.status}</span></td>
                                        </tr>
                                    })
                                    : <tr>
                                        <td colSpan={7}>Data tidak ditemukan</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-lg-8">
                    <Pagination page={page} pageUrl={index?.pageUrl} onChange={pageData} last_page={index?.last_page} />
                </div>
                <div className="col-lg-4">
                    <LimitTable perPage={perPage} onChange={changePerPage} />
                </div>
            </div>
        </div>
    </React.Fragment >
}

const mapStateToProps = state => {
    const { auth: { accessToken }, pertemuan: { index, del } } = state

    return { accessToken, index, del }
}

export default withRouter(connect(mapStateToProps, { actionFetchPertemuan, actionDeletePertemuan })(PertemuanIndex))