import actionTypes from './actionTypes'

export const actionFetchKursus = (data) => {
  return {
    type: actionTypes.FETCH_KURSUS,
    data
  }
}

export const actionFetchKursusStart = () => {
  return {
    type: actionTypes.FETCH_KURSUS_START
  }
}

export const actionFetchKursusEnd = () => {
  return {
    type: actionTypes.FETCH_KURSUS_END
  }
}

export const actionFetchKursusSuccess = (data) => {
  return {
    type: actionTypes.FETCH_KURSUS_SUCCESS,
    data
  }
}

export const actionCreateKursus = (data) => {
  return {
    type: actionTypes.CREATE_KURSUS,
    data
  }
}

export const actionCreateKursusStart = () => {
  return {
    type: actionTypes.CREATE_KURSUS_START
  }
}

export const actionCreateKursusEnd = () => {
  return {
    type: actionTypes.CREATE_KURSUS_END
  }
}

export const actionCreateKursusSuccess = (data) => {
  return {
    type: actionTypes.CREATE_KURSUS_SUCCESS,
    data
  }
}

export const actionCreateKursusFailed = (data) => {
  return {
    type: actionTypes.CREATE_KURSUS_FAILED,
    data
  }
}

export const actionUpdateKursus = (data) => {
  return {
    type: actionTypes.UPDATE_KURSUS,
    data
  }
}

export const actionUpdateKursusStart = () => {
  return {
    type: actionTypes.UPDATE_KURSUS_START
  }
}

export const actionUpdateKursusEnd = () => {
  return {
    type: actionTypes.UPDATE_KURSUS_END
  }
}

export const actionUpdateKursusSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_KURSUS_SUCCESS,
    data
  }
}

export const actionUpdateKursusFailed = (data) => {
  return {
    type: actionTypes.UPDATE_KURSUS_FAILED,
    data
  }
}

export const actionResetFormKursus = () => {
  return {
    type: actionTypes.RESET_FORM_KURSUS
  }
}

export const actionDeleteKursus = (data) => {
  return {
    type: actionTypes.DELETE_KURSUS,
    data
  }
}

export const actionDeleteKursusStart = () => {
  return {
    type: actionTypes.DELETE_KURSUS_START
  }
}

export const actionDeleteKursusEnd = () => {
  return {
    type: actionTypes.DELETE_KURSUS_END
  }
}

export const actionDeleteKursusSuccess = () => {
  return {
    type: actionTypes.DELETE_KURSUS_SUCCESS
  }
}

export const actionDeleteKursusFailed = () => {
  return {
    type: actionTypes.DELETE_KURSUS_FAILED
  }
}

export const actionResetDeleteKursus = () => {
  return {
    type: actionTypes.RESET_DELETE_KURSUS
  }
}