import Immutable from 'seamless-immutable'
import actionTypes from './actionTypes'
import { default as actionTypesAuth } from './../auth/actionTypes'

let model = {
  data: null
}
export const INITIAL_STATE = Immutable(model)

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.FETCH_NOTIFIKASI_SUCCESS:
      return {
        data: action?.data
      }

    case actionTypesAuth.LOGOUT:
      return { ...model }
    default:
      return state
  }
}

export default reducer
