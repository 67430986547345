import Immutable from 'seamless-immutable'

const FETCH_NOTIFIKASI = 'FETCH_NOTIFIKASI'
const FETCH_NOTIFIKASI_SUCCESS = 'FETCH_NOTIFIKASI_SUCCESS'

const actionTypes = Immutable({
  FETCH_NOTIFIKASI,
  FETCH_NOTIFIKASI_SUCCESS
})

export default actionTypes
