import history from "./history"

export const pushUrl = (url, search = null, data = null) => {
    return history.push({
        pathname: process.env.REACT_APP_SUBDIR + url,
        search,
        data
    })
}

export const changeUrl = (url, search = null, data = null) => {
    return window.history.replaceState(null, null, process.env.REACT_APP_SUBDIR + url);
}